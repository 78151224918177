import { PropTypes, useEffect, useState } from 'react'
import Layout from '../../Components/Layout'
import Header from '../../Components/Header/Header'
import { getUserData, setUserData, canAccess } from '../../Utils/LocalStorage'
import { clearDB } from '../../Utils/db'
import LogoEmpresa from '../../images/logo-empresa.jpg'
import { Link } from 'react-router-dom'

const HomePage = () => {
  const [username, setUsername] = useState('')

  useEffect(() => {
    setUsername(getUserData('fullname'))
  }, [])

  const cerrarSesion = () => {
    if (window.confirm('¿Realmente desea salir?')) {
      clearDB().then(() => {
        setUserData(null)
        window.location.reload(true)
      })
    }
  }

  return (
    <Layout>
      <Header titulo={username} />
      <div className='flex-auto w-full mt-28 text-center'>
        <img className='mx-auto rounded-md max-h-36' src={LogoEmpresa} alt='Logo' />
        <p className='font-display mt-4 font-bold text-sm'>Bienvenido a Abacus Mobile</p>
      </div>
      <div className='w-full flex-none mt-0 text-center font-display px-2'>
        <Link to='/clientes' className={`inline-block text-center w-full bg-primaryColor text-bgColor text-sm font-display font-bold p-2 mt-4 rounded-md shadow hover:bg-opacity-90 hover:shadow-lg ${canAccess('VerClientes') ? '' : 'hidden'}`}>Ir a Clientes</Link>
        <Link to='/existencias' className={`inline-block text-center w-full bg-primaryColor text-bgColor text-sm font-display font-bold p-2 mt-4 rounded-md shadow hover:bg-opacity-90 hover:shadow-lg ${canAccess('CrearAjusteStock') || canAccess('CrearControlDeInventarioStock') || canAccess('CrearNotaDePedidoCompras') || canAccess('CrearNotaDePedidoVentas') || canAccess('CrearRemitoCompras') ? '' : 'hidden'}`}>Ir a Productos</Link>
        <Link to='/productos' className={`inline-block text-center w-full bg-primaryColor text-bgColor text-sm font-display font-bold p-2 mt-4 mb-12 rounded-md shadow hover:bg-opacity-90 hover:shadow-lg ${canAccess('VerProductos') ? '' : 'hidden'}`}>Ir a Movimientos</Link>
        <button className='inline-block text-center w-full text-textColor text-xs font-display font-bold p-2 rounded-md bg-accent shadow' onClick={() => cerrarSesion()}>Cerrar sesión</button>
      </div>
    </Layout >
  )
}

export default HomePage
