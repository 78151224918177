import Dexie from 'dexie';

const db = new Dexie("abacusDB");

db.version(1).stores({
  productos: "++_id, id, idSubproducto, nombre, cantidad, precio, listasDePrecios, operacion",
  stock: "++_id, Id, IdSubproducto, IdDeposito, nombre, cantidad, tipo",
});


export const clearDB = async () => {
  db.delete();
}

export const addProducto = async (data, cantidad, precio, listaPrecio, operacion) => {
  const dbProducto = await db.productos.where({ 'id': data.IdProducto || data.id, 'operacion': operacion }).toArray();

  let producto = {
    id: data.IdProducto || data.id || 0,
    idSubproducto: data.IdSubProducto || data.idSubproducto || 0,
    operacion: operacion,
    nombre: data.Descripcion || data.nombre || '',
    cantidad: parseFloat(cantidad) || 0,
    precio: precio,
    listasDePrecios: operacion === 'venta' ? listaPrecio : {},
  };

  if (cantidad === 0) {
    if (dbProducto[0]?._id) {
      db.productos.delete(dbProducto[0]?._id);
    }
  } else {
    if (dbProducto.length > 0) {
      db.productos.update(dbProducto[0]?._id, producto);
    } else {
      db.productos.add(producto);
    }
  }
};

export const getProducto = async (idProducto, operacion) => {
  if (idProducto) {
    const productos = await db.productos.where({ 'id': idProducto, 'operacion': operacion }).first();
    return productos;
  }

  return {}
};

export const getImporteTotalProductos = async (operacion) => {
  const productos = await db.productos.where({ 'operacion': operacion || '' }).toArray();
  let importeTotal = 0;

  productos.forEach((producto) => {
    let importeParcial = producto.cantidad * producto.precio
    let porcentajeAplicar = importeParcial * (Math.abs(producto.listasDePrecios?.Porcentaje || 0) / 100);
    porcentajeAplicar = operacion === 'venta' ? porcentajeAplicar : 0
    importeTotal += importeParcial - porcentajeAplicar * (producto.listasDePrecios?.Porcentaje < 0 ? 1 : -1);
  });

  return importeTotal;
};

export const updateProducto = async (producto) => {
  db.productos.update(producto._id, producto).catch((err) => { return err });
};

export const getProductos = async (operacion) => {
  const productos = await db.productos.where({ 'operacion': operacion || '' }).toArray();
  return productos;
};

export const getTotalProductos = async (operacion) => {
  const productos = await db.productos.where({ 'operacion': operacion }).count();
  return productos;
};

export const deletePedido = async (operacion) => {
  db.productos.where({ 'operacion': operacion }).delete();
};


export const addStock = async (data) => {
  if (data._id) {
    db.stock.update(data._id, data);
  } else {
    db.stock.add(data);
  }
};

export const getStockItem = async (idProducto, idSubproducto, idDeposito, tipo) => {
  const stock = await db.stock.where({ 'Id': idProducto, 'IdSubproducto': idSubproducto, 'IdDeposito': idDeposito, 'tipo': tipo }).first();
  return stock;
};

export const deleteStockItem = async (idInterno) => {
  const res = await db.stock.delete(idInterno);
  return res;
};

export const getTotalStock = async (tipo) => {
  const stock = await db.stock.where({ 'tipo': tipo }).toArray();
  return stock;
};

export const deleteStock = async (tipo) => {
  db.stock.where({ 'tipo': tipo }).delete();
};
