import { toMoneyFormat } from '../../Utils/Format'

const ProductoItem = ({ data, onClick, tipoPedido }) => {
  return (
    <tr className='flex flex-row align-top font-display font-bold text-xs px-1 py-2'
      onClick={() => onClick(data)}
    >
      <td className='flex-auto text-left align-top uppercase font-normal pr-3'>
        <p className='text-secondaryColor font-bold lowercase break-all'>{data.Categoria}</p>
        <p className='uppercase'>{data.Codigo} - {data.Descripcion}</p>
        <p className='text-secondaryColor font-bold lowercase'>Existencia: {toMoneyFormat(data.Existencia)}</p>
      </td>
      <td className='flex-auto text-right align-top'>
        <h1 className='uppercase text-base -mt-1' style={{ whiteSpace: 'nowrap' }}>$ {toMoneyFormat(tipoPedido === 'compra' ? data.PrecioCompra : data.PrecioVenta)}</h1>
      </td>
    </tr>
  )
}

export default ProductoItem
