export function moneyType(type) {
  switch (type) {
    case 'DOL':
      return 'U$D';
    default:
      return '$';
  }
}

export const formatFecha = (text) => {
  if (text) {
    return new Intl.DateTimeFormat("es-AR", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    }).format(new Date(text));
  }
};

export const formatFechaISO = (text) => {
  let date = new Date(text);
  let dateString = new Date(date.getTime() - date.getTimezoneOffset() * 60000)
    .toISOString()
    .split("T")[0];

  return dateString;
};

export const toMoneyFormat = (number) => {
  return new Intl.NumberFormat("es-AR", {
    style: "currency",
    currency: "ARS",
  }).format(number).replace('$', '').trim();
};

export const formatNumber = (number) => {
  return new Intl.NumberFormat("es-AR", {
  }).format(number);
};
