import { useEffect, useState, useMemo } from 'react'
import Layout from '../../Components/Layout'
import Header from '../../Components/Header/Header'
import TextBox from '../../Components/TextBox/TextBox'
import Placeholder from '../../Components/Placeholder/Placeholder'
import ProductoItem from '../../ProductosStack/ProductosItem/ProductosItem'
import DetalleOverlay from '../../Components/DetalleOverlay/DetalleOverlay'
import ProductoDetalle from '../ExistenciaDetalle/ExistenciaDetalle'
import ComboBox from '../../Components/ComboBox'
import { getTokenHeader, setToken, setUserData, getUserData } from '../../Utils/LocalStorage'
import axios from 'axios'
import { useLocation, useHistory } from 'react-router-dom'
import Spinner from '../../Components/Spinner/Spinner'

import { IoSearch, IoChevronUp, IoPricetags } from "react-icons/io5";
import { toast } from 'react-toastify'

function useQuery() {
  const { search } = useLocation()
  return useMemo(() => new URLSearchParams(search), [search])
}


const ExistenciasBusqueda = () => {
  const [categoria] = useState({ Id: 0, Descripcion: 'TODAS' })
  const [producto, setProducto] = useState('')
  const [showDetalle, setShowDetalle] = useState(false)
  const [detalle, setDetalle] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [isModalLoading, setIsModalLoading] = useState(false)
  const [deposito, setDeposito] = useState({})
  const [listaPrecios, setListaPrecios] = useState({})

  const [tipoPedido] = useState({ id: 'VENTA', text: 'PEDIDO DE VENTA' })

  const [moveTop, setMoveTop] = useState(false)

  const [listaDepositos, setListaDepositos] = useState([])


  const [categoriasLoading, setCategoriasLoading] = useState(false)
  const [productos, setProductos] = useState([])
  const [cantidad, setCantidad] = useState(0)
  const [precio, setPrecio] = useState(0)
  const [existencias, setExistencias] = useState([])

  const [totalProductos, setTotalProductos] = useState(0)

  let query = useQuery()
  const history = useHistory();

  const cancelTokenSource = axios.CancelToken.source();

  useEffect(() => {
    cancelTokenSource.cancel('Reload');
    if (!query.get('norefresh')) {
      window.scrollTo(0, 0);
      setCategoriasLoading(true)
      history.push('/existencias/buscar?norefresh=true')
    }
  }, [query, cancelTokenSource, history])

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_ENDPOINT}/stock/GetDepositos`, getTokenHeader())
      .then(res => {
        // Insert an element TODOS in the first position
        let result = res.data.Result
        result.unshift(
          {
            Id: 0,
            Nombre: 'TODOS',
            IdSucursal: 0,
            Sucursal: "Test"
          })

        setDeposito(result.find(x => x.Id === parseInt(getUserData('deposito'))) || result[0])
        setListaDepositos(result)
      })
      .catch(err => {
        console.log(err)
      })
  }, [])


  const handleProductoChange = (val) => {
    setProducto(val)
    if (val === '') {
      setProductos([])
    }
  }

  // Handler para mostrar el detalle de un movimiento
  const handleShowDetalle = (producto) => {
    cancelTokenSource.cancel('Reload');
    setShowDetalle(true)
    setDetalle(producto)
    setIsModalLoading(true)
    axios.get(`${process.env.REACT_APP_ENDPOINT}/stock/GetListasDePrecioProducto?idProducto=${producto.IdProducto}&idDeposito=${deposito.id || deposito.Id}`, getTokenHeader(), { cancelToken: cancelTokenSource, progress: false }).then(res => {
      setListaPrecios(res.data.Result)

      axios.get(`${process.env.REACT_APP_ENDPOINT}/stock/GetInfoProducto?idProducto=${producto.IdProducto}&idSubproducto=${producto.IdSubProducto}&idDeposito=${deposito.id || deposito.Id}`, getTokenHeader(), { cancelToken: cancelTokenSource, progress: false })
        .then(res => {
          setExistencias(res.data.Result)
          setToken(res.data.RenewedAccessToken, res.data.RenewedAccessTokenExpireTime)
          setIsModalLoading(false)
        })
        .catch(err => {
          console.log(err)
          if (err?.response?.status === 401) {
            setToken('')
            window.location.href = '/login'
            setIsModalLoading(false)
          } else {
            setIsModalLoading(false)
            toast.error('Ocurrió un error al obtener la información del producto')
          }
        })

    })

    history.push(`/existencias/buscar?norefresh=true&openDetail=o&tipo=${tipoPedido.id}`)
  }

  const buscarProductos = () => {
    document.activeElement.blur()
    setIsLoading(true)
    axios.get(`${process.env.REACT_APP_ENDPOINT}/stock/GetExistencias?idCategoria=${categoria.id || categoria.Id}&contieneTexto=${producto}&incluirProductosSinExistencia=true&idDeposito=${deposito.id || deposito.Id}`, getTokenHeader())
      .then(res => {
        if (res.data.Result.length > 0) {
          setProductos(res.data.Result)
        } else {
          setProductos([])
        }
        setToken(res.data.RenewedAccessToken, res.data.RenewedAccessTokenExpireTime)
        setIsLoading(false)
      })
      .catch(err => {
        toast.error(err.response.data.ErrorMessage, {
          position: toast.POSITION.TOP_CENTER
        })

        setIsLoading(false)
        if (err.response.status === 401) {
          setToken('')
          window.location.href = '/login'
        }
      })
  }


  const handleScroll = () => {
    if (document.body.getBoundingClientRect().top < -30) {
      setMoveTop(true)
    } else {
      setMoveTop(false)
    }
  }

  return (
    <Layout>
      <Header titulo='Productos' subtitulo='' toAddress='/existencias' />

      <form className='mt-14 flex flex-col space-y-2'>
        <div className='flex-auto w-100'>
          <ComboBox
            name="listaDepositos"
            text="Deposito"
            placeholder='Ver listado'
            textFieldName="Nombre"
            value={deposito}
            items={listaDepositos}
            onChange={(val) => setDeposito(val)}
          />
        </div>
        <div className='flex flex-row'>
          <div className='flex-auto w-100'>
            <TextBox
              name="producto"
              label=""
              type="search"
              enterhint="done"
              placeholder="Filtrar producto"
              icon={<IoPricetags />}
              value={producto}
              onEnter={() => buscarProductos()}
              onChange={(val) => handleProductoChange(val)}
              autocomplete="off"
            />
          </div>
          <div className='w-1/12 text-right ml-1 mr-3 -mt-0'>
            <button type='button' className='rounded-full text-center bg-primaryColor p-3 text-bgColor' onClick={() => buscarProductos()}> <IoSearch /></button>
          </div>
        </div>
      </form>

      {isLoading && <Spinner />}
      {!isLoading &&
        <table className='mt-4 table mb-4' onWheel={() => handleScroll()} onTouchMove={() => handleScroll()}>
          {productos.map((item, key) => (
            <ProductoItem data={item}
              onClick={(val) => { handleShowDetalle(val) }}
              tipoPedido='venta'
            />
          ))
          }
        </table>
      }
      {productos.length === 0 && !isLoading &&
        < div >
          <Placeholder titulo='Sin resultado' texto='La búsqueda no arrojó ningún resultado. Realice una nueva búqueda.' />
        </div>
      }

      <button className={`fixed p-2 inline rounded-full bg-secondaryColor bottom-16 right-6 shadow-lg ${moveTop ? 'block' : 'hidden'}`} onClick={() => {
        window.scrollTo(0, 0);
      }}>
        <IoChevronUp className='text-2xl text-bgColor' />
      </button>


      <DetalleOverlay
        show={showDetalle}
        titulo={`Detalle de producto`}
        onClose={() => { history.goBack() && setExistencias(null) }}>

        <ProductoDetalle
          data={detalle}
          pedidos={cantidad}
          precio={precio}
          existencias={existencias}
          listadoPrecios={listaPrecios}
          isLoading={isModalLoading}
          tipoPedido={tipoPedido}
          isStockDetail={true}
          selectedDeposito={deposito.id || deposito.Id}
        />
      </DetalleOverlay>
    </Layout >
  )
}

export default ExistenciasBusqueda

