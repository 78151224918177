import { IoInformationCircleOutline } from "react-icons/io5";

const Placeholder = ({ titulo, texto }) => {
  return (
    <div className='mt-14 flex flex-col space-y-2 text-center text-textColor opacity-50'>
      <IoInformationCircleOutline className='text-5xl mx-auto mb-4' />
      <h2 className='font-display font-bold text-xl'>{titulo}</h2>
      <p className='font-display text-sm text-secondaryColor mt-5'>{texto}</p>
    </div>
  )

}

export default Placeholder;
