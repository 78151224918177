import { useState, useEffect } from 'react'
import Layout from '../../Components/Layout'
import Header from '../../Components/Header/Header'
import { Link } from 'react-router-dom'
import Stock from '../../images/stock.svg'
import { getTotalStock } from '../../Utils/db'
import { canAccess } from '../../Utils/LocalStorage'


const StockHome = () => {
  const [totalStock, setTotalStock] = useState([])

  useEffect(() => {
    getTotalStock('control').then(data => {
      setTotalStock(data)
    })
  }, [])

  return (
    <Layout>
      <Header titulo='Movimientos de stock' toAddress='/productos' />
      <div className='flex-auto w-full text-center'>
        <div className='w-full flex-none mt-24 text-center font-display px-2' style={{ scrollSnapAlign: 'start' }}>
          <img className='mx-auto rounded-md mb-4' src={Stock} alt='Logo' width='312' height='295' />
          <h1 className='font-bold text-lg'>Movimientos de stock</h1>
          <Link to='/stock/ajuste' className={`inline-block text-center w-full bg-primaryColor text-bgColor text-sm font-display font-bold p-2 rounded-md shadow hover:bg-opacity-90 hover:shadow-lg mt-4 ${canAccess('CrearAjusteStock') ? '' : 'hidden'}`}>Ajuste de stock</Link>
          <Link to={`/stock/control${totalStock.length > 0 ? `?depositoId=${totalStock[0].IdDeposito}` : ''}`} className={`inline-block text-center w-full bg-primaryColor text-bgColor text-sm font-display font-bold p-2 rounded-md shadow hover:bg-opacity-90 hover:shadow-lg mt-4 ${canAccess('CrearControlDeInventarioStock') ? '' : 'hidden'}`}>Control de inventario</Link>
          <Link to='/stock/compras' className={`inline-block text-center w-full bg-primaryColor text-bgColor text-sm font-display font-bold p-2 rounded-md shadow hover:bg-opacity-90 hover:shadow-lg mt-4 ${canAccess('CrearRemitoCompras') ? '' : 'hidden'}`}>Remito de compras</Link>
        </div>
      </div>
    </Layout >
  )
}

export default StockHome



