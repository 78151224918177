import { useState } from 'react'
import axios from 'axios'
import { getTokenHeader, setToken, setUserData } from '../../../Utils/LocalStorage'
import { moneyType, toMoneyFormat, formatFecha } from '../../../Utils/Format'


const CuentaDetalleListaComprobantes = ({ tipo, data, moneda, onClick, special, isHeader }) => {
  // Saldo del primer movimiento
  if (!data) {
    return null
  }

  return (
    <tr className={`flex flex-row align-top font-display font-bold text-xs px-1 py-2 cursor-pointer ${special ? 'mb-3 border-b-2' : ''}`} onClick={() => !(special || data.ObjectKey === '') && onClick(data.ObjectKey)}>
      <td className='flex-auto text-left align-top font-normal'>

        <p className={`uppercase text-primaryColor ${special ? 'hidden' : 'block'}`}>{formatFecha(data?.Fecha)}</p>
        <p className={`font-semibold uppercase text-sm`}>{data.Producto}</p>
        <p className={`font-semibold uppercase`}>{special ? 'Saldo a la fecha' : (data.Descripcion ? data.Descripcion : (data.Remito ? data.Remito : (data.NotaDePedido ? data.NotaDePedido : data.Factura)))}</p>
        <p className={`font-semibold`}>Cantidad: {data.Cantidad}</p>
        <p className={`font-semibold`}>Aplicaciones: {data.Aplicaciones}</p>
        <p className={`font-semibold`}>Resto por aplicar: {data.RestoPorAplicar}</p>
        <p className={`font-semibold ${data.DescripcionAplicaciones ? '' : 'hidden'}`}>Descripción</p>
        <p className={`font-normal`}>{data.DescripcionAplicaciones}</p>


      </td>
    </tr>
  )
}

export default CuentaDetalleListaComprobantes

