import { useEffect, useState, useMemo } from 'react'
import Layout from '../../Components/Layout'
import Header from '../../Components/Header/Header'
import ComboBox from '../../Components/ComboBox'
import ProductoItem from '../ProductosItemCart/ProductosItemCart'
import { deletePedido, getProductos, addProducto, getImporteTotalProductos } from '../../Utils/db'
import { toMoneyFormat } from '../../Utils/Format'
import { getTokenHeader, setToken } from '../../Utils/LocalStorage'
import TextBox from '../../Components/TextBox/TextBox'
import axios from 'axios'
import { toast } from 'react-toastify'
import DetalleOverlay from '../../Components/DetalleOverlay/DetalleOverlay'
import EdicionProductoForm from './EdicionProductoForm/EdicionProductoForm'

import { useLocation, useHistory } from 'react-router-dom'


// TODO: 
// Corregir error en compartir comprobantes
// Verificar si hay que sacar al usuario si expiró el token

const CheckoutFinishPage = ({ tipoPedido }) => {
  function useQuery() {
    const { search } = useLocation()
    return useMemo(() => new URLSearchParams(search), [search])
  }
  let query = useQuery()
  const history = useHistory();

  const handleEliminarPedido = () => {
    if (window.confirm('¿Realmente desea descartar el pedido?')) {
      deletePedido(tipoPedido)
      toast.success('Pedido eliminado')
      history.push('/productos')
    }
  }

  const [showDetalle, setShowDetalle] = useState(false)
  const [importeTotal, setImporteTotal] = useState(0)

  const [productos, setProductos] = useState([])
  const [cuentas, setCuentas] = useState([])
  const [vendedores, setVendedores] = useState([])
  const [condiciones, setCondiciones] = useState([])
  const [notas, setNotas] = useState('')

  const [cuenta, setCuenta] = useState({})
  const [vendedor, setVendedor] = useState({})
  const [condicion, setCondicion] = useState({})

  const [cuentaLoading, setCuentaLoading] = useState(true)
  const [vendedorLoading, setVendedorLoading] = useState(true)
  const [condicionLoading, setCondicionLoading] = useState(true)

  const [listadoPrecios, setListadoPrecios] = useState([])
  const [productoSeleccionado, setProductoSeleccionado] = useState({})

  useEffect(() => {
    getProductos(tipoPedido).then(productos => {
      setProductos(productos)
      getImporteTotalProductos(tipoPedido).then(importeTotal => {
        setImporteTotal(importeTotal)
      })
    })
  })

  const handleEditarProducto = (productoSeleccionado) => {
    axios.get(`${process.env.REACT_APP_ENDPOINT}/stock/GetListasDePrecioProducto?idProducto=${productoSeleccionado.id}`, getTokenHeader())
      .then(res => {
        setListadoPrecios(res.data.Result)
        setProductoSeleccionado(productoSeleccionado)
        setShowDetalle(true)
        history.push(`?u=${query.get('u')}&norefresh=true&openDetail=o`)
      })
      .catch(err => {
        console.log(err)
      })
  }

  const handleEnviarPedido = () => {
    if (window.confirm('¿Realmente desea enviar el pedido?')) {
      let endpoint = '/Compras/'
      let pedido = { Items: [] }
      if (tipoPedido === 'venta') {
        endpoint = '/Ventas/'
        pedido.IdCondicionVenta = condicion.Id ? parseInt(condicion.Id) : parseInt(condicion.id)
        pedido.IdCuentaCliente = cuenta.Id ? parseInt(cuenta.Id) : parseInt(cuenta.id)
        pedido.IdVendedor = vendedor.Id ? parseInt(vendedor.Id) : parseInt(vendedor.id)
      } else {
        delete pedido.IdCondicionVenta
        delete pedido.IdCuentaCliente
        delete pedido.IdVendedor
        pedido.IdCuentaProveedor = parseInt(query.get('ci'))
      }

      for (let i = 0; i < productos.length; i++) {
        let p = {
          IdProducto: productos[i].id,
          IdSubProducto: productos[i].idSubproducto,
          IdListaDePrecios: productos[i].listasDePrecios?.Id || productos[i].listasDePrecios?.id || 0,
          Cantidad: productos[i].cantidad,
          Precio: productos[i].precio,
        }
        pedido.Items.push(p)
      }
      pedido.Notas = notas
      axios.post(`${process.env.REACT_APP_ENDPOINT}${endpoint}/CreateNotaDePedido`, pedido, getTokenHeader())
        .then(res => {
          deletePedido(tipoPedido)
          setToken(res.data.RenewedAccessToken, res.data.RenewedAccessTokenExpireTime)
          toast.success(res.data.Result)
          history.push('/productos')
        })
        .catch(err => {
          toast.error(err.response.data.ErrorMessage, { autoClose: 10000 })
        })
    }
  }

  const handleItemDelete = (producto) => {
    let cantidadProductos = productos.length
    let newProducto = producto
    newProducto.IdProducto = producto.id
    addProducto(newProducto, 0, null, null, tipoPedido)
    setProductos(productos.filter(p => p.id !== producto.id))
    toast.success('Producto eliminado')
    if (cantidadProductos <= 1) {
      history.push('/productos')
    }
  }

  useEffect(() => {
    if (tipoPedido === 'venta') {
      axios.get(`${process.env.REACT_APP_ENDPOINT}/ventas/GetCuentasPorIdCliente?idCliente=${query.get('ai')}`, getTokenHeader())
        .then(resCuentas => {
          let cuentaCliente = query.get('ci')
          setCuentas(resCuentas.data.Result)
          let cuentaSeleccionada = resCuentas.data.Result.filter(el => el.Id == cuentaCliente)[0]
          setCuenta(cuentaSeleccionada)
          setToken(resCuentas.data.RenewedAccessToken, resCuentas.data.RenewedAccessTokenExpireTime)
          setCuentaLoading(false)
          axios.get(`${process.env.REACT_APP_ENDPOINT}/ventas/GetCondicionesVenta`, getTokenHeader())
            .then(res => {
              setCondiciones(res.data.Result)
              setToken(resCuentas.data.RenewedAccessToken, res.data.RenewedAccessTokenExpireTime)
              let condicionSeleccionada = res.data.Result.filter(el => el.Id == cuentaSeleccionada.DefaultIdCondicionDeVenta)[0]
              setCondicion(condicionSeleccionada)
              setCondicionLoading(false)
              axios.get(`${process.env.REACT_APP_ENDPOINT}/ventas/GetVendedores`, getTokenHeader())
                .then(res => {
                  let vendedorSeleccionado = res.data.Result.filter(el => el.Id == cuentaSeleccionada.DefaultIdVendedor)[0]
                  setVendedores(res.data.Result)
                  setVendedor(vendedorSeleccionado)
                  setToken(resCuentas.data.RenewedAccessToken, res.data.RenewedAccessTokenExpireTime)
                  setVendedorLoading(false)
                  window.scrollTo(0, 0);
                })
                .catch(err => {
                  if (err.response.status === 401) {
                    setToken('')
                    history.push('/login')
                  }
                })
            })
            .catch(err => {
              if (err.response.status === 401) {
                setToken('')
                history.push('/login')
              }
            })
        })
        .catch(err => {
          console.log(err)
          if (err.response?.status === 401) {
            setToken('')
            history.push('/login')
          }
        })
    }
  }, [history, query, tipoPedido])


  return (
    <Layout>
      <Header titulo={atob(query.get('u'))} subtitulo={`pedido de ${tipoPedido}`} toAddress={`/productos/${tipoPedido}/checkout`} />

      <div className={`mt-14 flex flex-row ${tipoPedido === 'venta' ? '' : 'hidden'}`}>
        <div className='flex-auto w-8/12 pr-1'>
          <ComboBox
            name="cuenta"
            text="Cuenta"
            placeholder='Cuenta del cliente'
            items={cuentas}
            textFieldName="NombreCompleto"
            value={cuenta}
            onChange={(e) => { setCuenta(e) }}
            loading={cuentaLoading}
          />
        </div>
      </div>

      <div className={`mt-3 flex flex-row ${tipoPedido === 'venta' ? '' : 'hidden'}`}>
        <div className='flex-auto w-8/12 pr-1'>
          <ComboBox
            name="condicion"
            text="Condición de venta"
            placeholder='Condición de venta'
            items={condiciones}
            textFieldName="Descripcion"
            value={condicion}
            onChange={(e) => { setCondicion(e) }}
          />
        </div>
      </div>
      <div className={`mt-3 flex flex-row ${tipoPedido === 'venta' ? '' : 'hidden'}`}>
        <div className='flex-auto w-8/12 pr-1'>
          <ComboBox
            name="Vendedores"
            text="Vendedor"
            placeholder='Vendedores'
            items={vendedores}
            textFieldName="Descripcion"
            value={vendedor}
            onChange={(e) => { setVendedor(e) }}
            isLoading={vendedorLoading}
          />
        </div>
      </div>
      <div className={`mt-3 flex flex-row ${tipoPedido === 'venta' ? '' : 'mt-14'}`}>
        <TextBox
          className='w-full'
          name="productos"
          label="Notas (opcional)"
          type="text"
          enterhint="next"
          placeholder="Ingresar una nota interna"
          autocomplete="off"
          multiline={true}
          lines={3}
          value={notas}
          onChange={(e) => setNotas(e)}
        />
      </div>


      <div className={`mt-3 flex flex-row ${tipoPedido === 'venta' ? '' : 'mt-14'}`}>
        <h3 className='text-xl '>Total pedido <span className='font-bold font-display'>$ {toMoneyFormat(importeTotal)}</span></h3>
      </div>
      <table className='mt-4 table'>
        {productos?.map((item, key) => (
          <ProductoItem producto={item} onDelete={(producto) => handleItemDelete(producto)} tipoPedido={tipoPedido} onDetalle={(producto) => handleEditarProducto(producto)} showButton={true} />
        ))}
      </table>


      <div className={`flex flex-row mt-10 ${productos.length > 0 ? '' : 'hidden'}`}>
        <button to='/productos' className='inline-block text-center w-full bg-secondaryColor text-bgColor text-sm font-display font-bold p-2 rounded-md shadow hover:bg-opacity-90 hover:shadow-lg mt-4 mr-1' onClick={() => { handleEliminarPedido() }}>Descartar</button>
        <button to='/cuentas' className='inline-block text-center w-full bg-primaryColor text-bgColor text-sm font-display font-bold p-2 rounded-md shadow hover:bg-opacity-90 hover:shadow-lg mt-4 ml-1' onClick={() => handleEnviarPedido()}>Enviar</button>
      </div>

      <DetalleOverlay
        show={showDetalle}
        titulo={`Edición del producto`}
        onClose={() => { history.goBack() }}>

        <EdicionProductoForm producto={productoSeleccionado} listadoPrecios={listadoPrecios} tipoPedido={tipoPedido} />

      </DetalleOverlay>
    </Layout >
  )
}

export default CheckoutFinishPage

