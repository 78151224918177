import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { IoSyncOutline } from "react-icons/io5";


/**
 * Componente generico para ingreso textual.
 */
function TextBox(props) {
  const [startDate, setStartDate] = useState(props.value);
  let cssClass = `border border-secondaryColor focus:outline-none focus:ring-2 focus:ring-primaryColor focus:border-bgColor w-full p-1 py-2 rounded bg-bgColor ${props.icon ? 'pl-8' : ''} ${props.className}`

  const handleChange = (e) => {
    if (props.type === "ndate") {
      setStartDate(e);
      props.onChange(e);
    } else {
      props.onChange && props.onChange(e.target.value);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      if (props.type === "ndate") {
        setStartDate(e);
        props.onEnter(e);
      } else {
        props.onEnter && props.onEnter(e.target.value);
      }
    }
  };

  return (
    <div className='control'>
      <label htmlFor={props.name}
        className='text-xs font-display font-bold'
        style={{ color: props.errorState ? "red" : null }}
      >
        {props.required ? (props.label ? "*" : "") : null} {props.label}
      </label>
      {props.multiline && props.multiline && (
        <textarea
          className={cssClass}
          value={props.value}
          placeholder={props.placeholder}
          autoComplete={props.autocomplete}
          rows={props.lines}
          style={{ borderColor: props.errorState ? "red" : null }}
          onChange={handleChange}
          disabled={props.disabled}
          required={props.required ? true : null}
          onKeyDown={handleKeyDown}
        ></textarea>
      )}

      {!props.multiline && (
        <input
          className={cssClass}
          value={props.value}
          list={"list-" + props.name}
          id={props.name}
          name={props.name}
          placeholder={props.placeholder}
          autoComplete={props.autocomplete}
          type={props.type}
          enterhint={props.enterhint}
          required={props.required ? true : null}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          style={{ borderColor: props.errorState ? "red" : null }}
          min={props.min}
          max={props.max}
          disabled={props.disabled}
          pattern={props.type === "numeric" ? "[0-9]*" : null}
          size={props.size}
          maxLength={props.size}
        />
      )}
      {props.icon && (
        <div className='absolute -mt-7 ml-2'>
          {props.icon}
        </div>
      )}
      {props.loading && (
        <IoSyncOutline />
      )}
      <small className="textbox--error-text">
        {props.errorState && props.errorState}
      </small>
      <div className={`loader ${props.isLoading ? '' : 'hidden'}`}>
        <div className="dot-pulse text-primaryColor"></div>
      </div>

      {props.datalist && (
        <datalist id={"list-" + props.name}>
          {props.datalist.map((item, key) => (
            <option key={key} value={item.Descripcion} />
          ))}
        </datalist>
      )}
    </div>
  );
}

TextBox.propTypes = {
  /** El nombre del textbox */
  name: PropTypes.string.isRequired,
  /** Texto de la etiqueta */
  label: PropTypes.string.isRequired,
  /** Texto dentro del cuadro de texto */
  placeholder: PropTypes.string,
  /** Indicado de autocompletado */
  autocomplete: PropTypes.oneOf([
    "''",
    "username",
    "current-password",
    "email",
  ]),
  /** Indicador de tipo de textbox */
  type: PropTypes.oneOf([
    "email",
    "number",
    "password",
    "search",
    "tel",
    "text",
    "text",
    "url",
    "date",
  ]),
  /** Indicador de tecla enter (uso en móvil) */
  enterhint: PropTypes.string,
  /** Es campo requerido (agrega un asterisco) */
  required: PropTypes.bool,
  /** Estado de validación del componente. True tiene un error */
  errorState: PropTypes.string,
  /** Estado de carga */
  loading: PropTypes.bool,
  /** Si acepta múltiples líneas */
  multiline: PropTypes.bool,
  /** Cantidad de líneas que se muestran */
  lines: PropTypes.number,
  /** Valor mínimo que acepta */
  min: PropTypes.number,
  /** Valor máximo que acepta */
  max: PropTypes.number,
  /** Estado de edición del texto */
  enabled: PropTypes.bool,
  /** Indicador de carga de datos */
  isLoading: PropTypes.bool,
};

TextBox.defaultProps = {
  name: "TextBox",
  label: "TextBox",
  placeholder: "",
  autocomplete: "",
  type: "text",
  enterhint: "next",
  required: false,
  errorState: "",
  loading: false,
  multiline: false,
  lines: 10,
  min: 0,
  max: 9999,
  enabled: true,
  isLoading: false,
};

export default TextBox;
