import { useMemo } from 'react'
import { IoClose } from "react-icons/io5";
import { useLocation } from 'react-router-dom'

const DetalleOverlay = (props) => {
  function useQuery() {
    const { search } = useLocation()
    return useMemo(() => new URLSearchParams(search), [search])
  }
  let query = useQuery()

  return (
    <div className={`${query.get('openDetail') == 'o' ? 'block' : 'hidden'} fixed bg-black bg-opacity-80 w-screen h-screen top-0 left-0 pb-4 z-20 overflow-auto`} style={{ overscrollBehavior: 'contain' }}>
      <div className='container bg-bgColor absolute bottom-0 left-0 w-screen rounded-t-xl p-4
    overflow-auto' style={{ maxHeight: '75vh', overscrollBehavior: 'contain' }}>
        <div className='flex flex-row'>
          <div className='flex-auto text-bold font-display font-bold mb-4'>
            <h1>
              {props.titulo}
            </h1>
          </div>
          <div className='font-display font-bold'>
            <button className='inline-block mr-2 -mt-1 rounded-full shadow-md p-2 bg-accent' onClick={props.onClose}><IoClose /></button>
          </div>
        </div>

        <div className='pb-20'>
          {props.children}
        </div>
      </div>
    </div>
  )
}

export default DetalleOverlay

