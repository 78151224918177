import { useEffect, useState, useMemo } from 'react'
import Layout from '../../../Components/Layout'
import Header from '../../../Components/Header/Header'
import TextBox from '../../../Components/TextBox/TextBox'
import ComboBox from '../../../Components/ComboBox'
import Placeholder from '../../../Components/Placeholder/Placeholder'
import StockItem from './StockItem/StockItem'
import DetalleOverlay from '../../../Components/DetalleOverlay/DetalleOverlay'
import StockDetalle from './StockDetalle/StockDetalle'
import { getTokenHeader, setToken, getUserData } from '../../../Utils/LocalStorage'
import axios from 'axios'

import { Link, useLocation, useHistory } from 'react-router-dom'
import { deleteStock, getStockItem, getTotalStock } from '../../../Utils/db'
import Spinner from '../../../Components/Spinner/Spinner'

import { IoChevronUp, IoPricetags, IoBarcode, IoCube, IoSearch } from "react-icons/io5";
import { toast } from 'react-toastify'

function useQuery() {
  const { search } = useLocation()
  return useMemo(() => new URLSearchParams(search), [search])
}


const StockMainPage = ({ tipoPedido, nombre }) => {
  const [producto, setProducto] = useState('')
  const [showDetalle, setShowDetalle] = useState(false)
  const [detalle, setDetalle] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [loading, setLoading] = useState(false)

  const [listaDepositos, setListaDepositos] = useState([])
  const [deposito, setDeposito] = useState({})

  const [moveTop, setMoveTop] = useState(false)


  const [productos, setProductos] = useState([])
  const [dbItem, setDbItem] = useState({})

  const [totalProductos, setTotalProductos] = useState(0)

  let query = useQuery()
  const history = useHistory();


  // Handler para mostrar el detalle de un producto
  const handleShowDetalle = (producto) => {
    setShowDetalle(true)
    setDetalle(producto)
    getStockItem(producto.IdProducto, producto.IdSubProducto, parseInt(deposito.id || deposito.Id), tipoPedido).then(res => {
      setDbItem(res)
    })
    history.push(`/stock/${tipoPedido}?norefresh=true&openDetail=o&tipo=${tipoPedido}&depositoId=${deposito.Id || deposito.id}`)
  }

  useEffect(() => {
    getTotalStock(tipoPedido, parseInt(query.get('depositoId'))).then(res => {
      setTotalProductos(res)
      let barcode = query.get('barcode') || ''
      setProducto(barcode)
      setLoading(true)
      axios.get(`${process.env.REACT_APP_ENDPOINT}/stock/GetDepositos`,
        getTokenHeader(),
      ).then(res => {
        setLoading(false)
        let result = res.data.Result
        result.unshift(
          {
            Id: 0,
            Nombre: 'TODOS',
            IdSucursal: 0,
            Sucursal: "Test"
          })
        setListaDepositos(res.data.Result)
        let depositoId = parseInt(query.get('depositoId')) || null
        let depositoSeleccionado = result.find(x => x.Id === parseInt(getUserData('deposito'))) || result[0]
        setDeposito(depositoSeleccionado)


        if (!query.get('norefresh')) {
          history.push(`/stock/${tipoPedido}/?norefresh=true${barcode ? `&barcode=${barcode}` : ''}${depositoSeleccionado ? `&depositoId=${depositoSeleccionado.Id}` : ''}`)
          if (barcode) { buscarProductos(depositoSeleccionado.Id, barcode) }
        } else {
          window.scrollTo(0, 0);
        }
        setIsLoading(false)
      }).catch(err => {
        console.log(err)
      })
    })
  }, [history, tipoPedido, query])

  const buscarProductos = (depositoId, barcode) => {
    document.activeElement.blur()
    setIsLoading(true)
    axios.get(`${process.env.REACT_APP_ENDPOINT}/stock/GetExistencias?idCategoria=0&contieneTexto=${barcode || producto}&incluirProductosSinExistencia=true&idDeposito=${depositoId || deposito.Id || deposito.id}`, getTokenHeader())
      .then(res => {
        if (res.data.Result.length > 0) {
          setProductos(res.data.Result)
        } else {
          setProductos([])
        }
        setToken(res.data.RenewedAccessToken, res.data.RenewedAccessTokenExpireTime)
        setIsLoading(false)
      })
      .catch(err => {
        toast.error(`Hubo un problema al recuperar la información: ${err.response?.data.ErrorMessage || 'sin conexión'}`, {
          position: toast.POSITION.TOP_CENTER
        })

        setIsLoading(false)
        if (err.response?.status === 401) {
          setToken('')
          window.location.href = '/login'
        }
      })
  }


  const handleScroll = () => {
    if (document.body.getBoundingClientRect().top < -30) {
      setMoveTop(true)
    } else {
      setMoveTop(false)
    }
  }

  const handleDeposito = (nuevoDeposito) => {
    let depositoViejo = deposito
    setDeposito(nuevoDeposito)
    if (tipoPedido === 'control' && totalProductos.length > 0) {
      if (window.confirm('¿Está seguro que desea cambiar el deposito?')) {
        setDeposito(nuevoDeposito)
        deleteStock('control')
        setProductos([])
        setProducto('')
        setTotalProductos([])
      } else {
        // FIX: Resetear el select despues de cambiar el deposito
        window.setTimeout(() => {
          setDeposito(depositoViejo)
        }, 100)
        return
      }
    } else {
      setProductos([])
      setProducto('')
      setDeposito(nuevoDeposito)
    }
  }


  return (
    <Layout>
      <Header titulo={nombre} subtitulo='' toAddress='/stock' />


      <form className='mt-14 flex flex-col space-y-2'>
        <div className='flex flex-row'>
          <div className='flex-auto w-auto'>
            <ComboBox
              name="deposito"
              text="Depósito"
              placeholder='Lista de depósitos'
              textFieldName="Nombre"
              items={listaDepositos}
              value={deposito}
              onChange={(val) => { handleDeposito(val) }}
              isLoading={loading}
            />
          </div>
        </div>
        <div className='flex flex-row'>
          <div className='flex-auto w-auto'>
            <TextBox
              name="producto"
              label=""
              type="search"
              enterhint="done"
              placeholder="Filtrar producto"
              icon={<IoPricetags />}
              value={producto}
              onEnter={() => buscarProductos()}
              onChange={(val) => setProducto(val)}
              autocomplete="off"
            />
          </div>
          <div className='w-1/12 text-right ml-1 mr-3 -mt-0'>
            <button type='button' className='rounded-full text-center bg-primaryColor p-3 text-bgColor' onClick={() => buscarProductos()}> <IoSearch /></button>
          </div>
          <div className='w-1/12 text-right ml-1 mr-3 -mt-0'>
            <Link type='button' className='rounded-full text-center bg-primaryColor p-3 text-bgColor' to={`/stock/${tipoPedido}/barcode?${deposito ? `depositoId=${deposito.id || deposito.Id}` : ''}`}> <IoBarcode /></Link>
          </div>
        </div>
      </form>

      {isLoading && <Spinner />}
      {
        !isLoading &&
        <table className='mt-4 table mb-4' onWheel={() => handleScroll()} onTouchMove={() => handleScroll()}>
          {productos.map((item, key) => (
            <StockItem key={key} data={item}
              onClick={(val) => { handleShowDetalle(val) }}
            />
          ))
          }
        </table>
      }
      {productos.length === 0 && !isLoading &&
        <div>
          <Placeholder titulo='Sin resultado' texto='La búsqueda no arrojó ningún resultado. Realice una nueva búqueda.' />
        </div>
      }

      <Link to={tipoPedido === 'compras' ? `/stock/${tipoPedido}/checkout` : `/stock/${tipoPedido}/checkout/finalizar`} className={`fixed p-4 inline rounded-full bg-primaryColor bottom-16 left-6 shadow-lg ${totalProductos.length > 0 ? 'block' : 'hidden'}`}>
        <IoCube className='text-2xl text-bgColor' />
        <span className={`absolute text-bgColor bg-textColor rounded-full -right-1 -top-2 p-1 font-bold ${totalProductos.length > 0 ? 'block' : 'hidden'} ${totalProductos.length < 11 ? 'px-2' : 'hidden'}`} style={{ fontSize: ".7rem" }}>{totalProductos.length}</span>
      </Link>


      <button className={`fixed p-2 inline rounded-full bg-secondaryColor bottom-16 right-6 shadow-lg ${moveTop ? 'block' : 'hidden'}`} onClick={() => {
        window.scrollTo(0, 0);
      }}>
        <IoChevronUp className='text-2xl text-bgColor' />
      </button>


      <DetalleOverlay
        show={showDetalle}
        titulo={`Detalle de producto`}
        onClose={() => {
          history.push(`/stock/${tipoPedido}?norefresh=true&depositoId=${deposito.id || deposito.Id}`)
        }}>

        <StockDetalle data={detalle} dbItem={dbItem} tipoPedido={tipoPedido} depositoId={deposito.id || deposito.Id} />
      </DetalleOverlay>
    </Layout >
  )
}

export default StockMainPage
