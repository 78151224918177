import { useEffect, useState } from 'react'
import { toMoneyFormat, formatFecha } from '../../../Utils/Format'
import { IoCloudDownloadOutline, IoShareSocialOutline } from "react-icons/io5";
import axios from 'axios'
import { getTokenHeader, getToken, setToken, setUserData } from '../../../Utils/LocalStorage'

const CuentaDetalle = ({ data }) => {
  const [file, setFile] = useState([])

  useEffect(() => {
    fetch(`${process.env.REACT_APP_ENDPOINT}/comprobantes/GetPDF?objectKey=${data.objectKey}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${getToken()}`
      }
    }).then(res => res.blob()).then(blob => {
      setFile(blob)
    })
  }, [data])


  const DownloadPDF = (objectKey, filename) => {
    fetch(`${process.env.REACT_APP_ENDPOINT}/comprobantes/GetPDF?objectKey=${objectKey}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${getToken()}`
      }
    }).then(res => res.blob()).then(blob => {
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${filename}.pdf`);
      document.body.appendChild(link);
      link.click();
    })
  };

  async function ShareFile(objectKey, filename) {
    console.log(`${filename}.pdf`)
    if (navigator.share) {
      fetch(`${process.env.REACT_APP_ENDPOINT}/comprobantes/GetPDF?objectKey=${objectKey}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${getToken()}`
        }
      }).then(res => res.blob()).then(blob => {
        let file = new File([blob], `${filename}.pdf`, { type: 'application/pdf' });
        navigator
          .share({
            files: [file],
            title: `Compartir ${filename}`,
          })
          .catch((error) => {
            console.error("Something went wrong", error);
          });
      })
    }
  };



  return (
    <div>
      <div className='flex flex-row align-top font-display font-bold mt-2 overflow-auto' style={{ overscrollBehavior: 'contain' }}>
        <div className='flex-auto text-left align-top uppercase text-sm'>
          <p className='uppercase text-secondaryColor font-bold text-sm'>{formatFecha(data?.Fecha)}</p>
          <p className='font-semibold uppercase'>{data.Tipo} Nº {data.NroComprobante}</p>
        </div>
        <div className='flex-auto text-right align-bottom'>
          <h1 className='uppercase text-base mt-4'>$ {toMoneyFormat(data.Importe)}</h1>
        </div>
      </div>
      <div className='flex flex-row align-top font-display font-bold mt-2'>
        <div className='flex-auto text-left align-top text-xs'>
          <p className={`${data.CondicionVenta ? 'block' : 'hidden'} font-semibold`}>Cond. Vta.: <span className='uppercase'>{data.CondicionVenta}</span></p>
          <p className='font-semibold'>Vendedor: <span className='uppercase'>{data.Vendedor}</span></p>
        </div>
      </div>

      <table className='mt-8 table w-full'>
        {
          data.Items?.map((item) => {
            return <tr className='flex flex-row align-top font-display font-bold text-xs px-1 py-2'>
              <td className='flex-auto text-left align-top uppercase font-normal'>
                <p className='font-semibold uppercase'>{item.Descripcion}</p>
                <p className={`${item.Codigo ? "block" : "hidden"} uppercase text-secondaryColor font-semibold`}>{item.Cantidad} x {toMoneyFormat(item.Precio)}</p>
              </td>
              <td>
                <h1 className='uppercase text-base -mt-1' style={{ whiteSpace: 'nowrap' }}>$ {toMoneyFormat(item.Importe)}</h1>
              </td>
            </tr>
          })}
      </table >

      <div className=' mt-5 text-sm flex flex-row align-top font-display'>
        <div className='flex-auto text-center'>
          <button className='bg-primaryColor text-bgColor inline-block rounded-full shadow-md p-2 px-4'
            onClick={() => DownloadPDF(data.objectKey, `${data.Tipo.replace(' ', '_')}_${data.NroComprobante}`)}>
            <IoCloudDownloadOutline className='inline mr-2 -mt-1' />
            Descargar
          </button>
        </div>
        <div className='flex-auto text-center'>
          <button className='bg-primaryColor text-bgColor inline-block rounded-full shadow-md p-2 px-4'
            onClick={() => ShareFile(data.objectKey, `${data.Tipo.replace(' ', '_')}_${data.NroComprobante}`)}>
            <IoShareSocialOutline className='inline mr-2 -mt-1' />
            Compartir
          </button>
        </div>
      </div>

    </div >
  )
}

export default CuentaDetalle


