import Layout from '../Components/Layout'
import Header from '../Components/Header/Header'
//import Carousel from './Carousel/Carousel'
import { Link } from 'react-router-dom'
import Existencias from '../images/existencias.svg'

const ExistenciasHome = () => {

  return (
    <Layout>
      <Header titulo='Productos' toAddress='/home'/>
      <div className='flex-auto w-full text-center'>
          <div className='w-full flex-none mt-24 text-center font-display px-2' style={{ scrollSnapAlign: 'start' }}>
            <img className='mx-auto rounded-md mb-4' src={Existencias} alt='Logo' width='312' height='304'/>
            <h1 className='font-bold text-lg'>Productos</h1>
            <Link to='/existencias/buscar' className='inline-block text-center w-full bg-primaryColor text-bgColor text-sm font-display font-bold p-2 rounded-md shadow hover:bg-opacity-90 hover:shadow-lg mt-4'>ver precios y existencias</Link>
          </div>
      </div>
    </Layout >
  )
}

export default ExistenciasHome
