export function setUserData(apiResponse) {
  let storage = window.localStorage;
  if (apiResponse) {
    storage.setItem("username", apiResponse.Usuario);
    storage.setItem("fullname", apiResponse.UsuarioNombreCompleto);
    storage.setItem("permisos", JSON.stringify(apiResponse.Permisos));
    storage.setItem("token", apiResponse.AccessToken);
    storage.setItem("puesto", apiResponse.PuestoDeTrabajo);
    storage.setItem("sucursal", apiResponse.Sucursal);
    storage.setItem("empresa", apiResponse.Empresa);
    storage.setItem("deposito", apiResponse.IdDepositoAsociado);
  } else {
    storage.removeItem("username");
    storage.removeItem("fullname");
    storage.removeItem("roles");
    storage.removeItem("token");
    storage.removeItem("puesto");
    storage.removeItem("sucursal");
    storage.removeItem("empresa");
    storage.removeItem("expiresAt");
  }
}

export function setToken(token, expiration) {
  let storage = window.localStorage;
  storage.setItem("token", token);
  storage.setItem("expiresAt", expiration);
}

export function tokenExpired() {
  let storage = window.localStorage;
  let expiration = storage.getItem("expiresAt");
  let token = storage.getItem("token");
  if (expiration) {
    let now = new Date();
    let expirationDate = new Date(expiration);

    if (now > expirationDate) {
      return true;
    } else {
      return false;
    }
  } else {
    if (token) {
      return true;
    } else {
      return false;
    }
  }
}

export const getToken = () => {
  let storage = window.localStorage;
  return storage.getItem("token");
};

export const getTokenHeader = () => {
  let token = getToken();
  return { headers: { Authorization: `Bearer ${token}` } };
};

export const getUserData = (key) => {
  let storage = window.localStorage;
  if (key === 'roles') {
    return JSON.parse(storage.getItem(key));
  } else {
    return storage.getItem(key);
  }
};

export const logout = () => {
  setUserData();
  window.location.href = "/";
}

export const canAccess = (permiso) => {
  let storage = window.localStorage;
  let userRoles = JSON.parse(storage.getItem("permisos"));
  return userRoles[permiso]
}
