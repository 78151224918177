import { IoTrashBin, IoEllipsisVerticalSharp } from "react-icons/io5";
import { formatNumber, toMoneyFormat } from '../../../../Utils/Format'

const ProductoItem = ({ producto, onDelete, onDetalle, showButton, listaDepositos }) => {
  return (
    <tr className='flex flex-row align-top font-display font-bold text-xs px-1 py-2'>
      <td className='flex-auto text-left align-top uppercase font-normal w-2/12' onClick={() => { onDelete(producto) }}>
        <p className='inline-block uppercase rounded-full shadow bg-bgColor p-2'><IoTrashBin className='text-primaryColor text-lg' /></p>
      </td>
      <td className='flex-auto text-left align-top font-normal py-1 w-9/12'>
        <p className='uppercase font-bold text-secondaryColor'>{producto?.nombre}</p>
        <p className='uppercase text-xs'>Dep.: <b>{listaDepositos.find(deposito => deposito.Id === producto.IdDeposito)?.Nombre}</b></p>
        <p className='text-xs'>Cantidad: <b className='text-sm'>{toMoneyFormat(producto.Cantidad)}</b></p>
      </td>
      <td className={`flex-auto text-left align-top uppercase font-normal w-2/12 ${showButton ? '' : 'hidden'}`} >
        <p className='inline-block uppercase rounded-full shadow bg-bgColor p-2' onClick={() => onDetalle(producto)}><IoEllipsisVerticalSharp className='text-secondaryColor text-lg' /></p>
      </td>
    </tr>
  )
}

export default ProductoItem

