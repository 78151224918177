import { IoArrowBack } from "react-icons/io5";
import { Link, useHistory } from 'react-router-dom'
import Logo from '../../images/abacus-s.png'
import { getUserData } from '../../Utils/LocalStorage'


const Header = ({ titulo, subtitulo, toAddress, children }) => {
  const history = useHistory();
  return (
    <div className='flex fixed top-0 left-0 w-screen max-w-screen-sm bg-bgColor pt-4 pb-2 px-4 z-10 shadow-sm'>
      <div className={`font-display font-bold ${toAddress ? '' : 'hidden'}`}>
        <div onClick={() => { history.push(toAddress) }} className='inline-block mr-2 rounded-full shadow-md p-2'><IoArrowBack /></div>
      </div>
      <div className='flex-auto font-display font-bold text-left -mt-1'>
        <h1 className='text-xs font-bold'>{getUserData('empresa')}</h1>
        {children}
        <span className='inline-block align-middle leading-tight'>{titulo?.length > 21 ? titulo?.substring(0, 19) + '...' : titulo}</span>
        <span className=' uppercase block align-middle text-secondaryColor text-xs -mt-1'>{titulo?.length > 20 ? "" : subtitulo}</span>
      </div>
      <Link className='text-right' to='/home'>
        <img className='rounded-md w-20' src={Logo} alt='Logo' />
      </Link>
    </div >
  )
}

export default Header
