import { useState, useEffect, useMemo } from 'react'
import { toMoneyFormat, formatFecha } from '../../Utils/Format'
import { Link, useLocation, useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import Spinner from '../../Components/Spinner/Spinner'

function useQuery() {
  const { search } = useLocation()
  return useMemo(() => new URLSearchParams(search), [search])
}

const ExistenciaDetalle = ({ data, pedidos, precio, existencias, isLoading, isStockDetail, selectedDeposito }) => {
  const [cantidad, setCantidad] = useState(pedidos)
  const [precioVenta, setPrecioVenta] = useState(precio)
  const [listaPrecios, setListaPrecios] = useState([])
  const history = useHistory();
  const [canEdit, setCanEdit] = useState(false)
  let query = useQuery()

  // <button className='mt-4 bg-primaryColor w-full text-bgColor inline-block rounded shadow-md p-2 px-4 font-bold' onClick={() => history.push(`/existencias/movimientos?producto=${data?.IdProducto}&subproducto=${data?.IdSubProducto}&deposito=${selectedDeposito}`)}>Ver movimientos</button>

  useEffect(() => {
    setCantidad(pedidos)
    setPrecioVenta(precio)
    query.get('tipo')
  }, [pedidos, precio, data])

  return (
    <div className='pb-2'>
      <div className='flex flex-row align-top font-display font-bold mt-2'>
        <div className='flex-auto text-left align-top text-sm'>
          <p className='text-secondaryColor font-bold text-sm'>Cod. {data.Codigo}</p>
          <p className='text-base font-bold uppercase'>{data.Descripcion}</p>
          <p className='font-semibold'>Precio: <span className='uppercase text-primaryColor'>$ {toMoneyFormat(data.PrecioVenta)}</span></p>
        </div>
      </div>
      {isLoading && <Spinner />}
      {!isLoading &&
        <div className="mb-4">
          <div className='flex flex-row align-top font-display font-bold mt-2'>
            <div className='flex-auto text-left align-top text-xs'>
              <p className='font-semibold mt-4'>Existencias: <span className='uppercase'>{existencias.Existencia}</span></p>
              <p className='font-semibold'>Facturas pendientes (compras): <span className='uppercase'>{existencias.FacturasPendientesCompras}</span></p>
              <p className='font-semibold'>Facturas pendientes (ventas): <span className='uppercase'>{existencias.FacturasPendientesVentas}</span></p>
              <p className='font-semibold'>N. Pedidos pendientes (compras): <span className='uppercase'>{existencias.NotasDePedidoPendientesCompras}</span></p>
              <p className='font-semibold'>N. Pedidos pendientes (ventas): <span className='uppercase'>{existencias.NotasDePedidoPendientesVentas}</span></p>
              <p className='font-semibold mt-4 text-sm'>Total existencias: <span className='uppercase'>{existencias.ExistenciaFinal}</span></p>
            </div>
          </div>
          <div className={`flex flex-row align-top font-display mt-4 ${existencias.Depositos?.length > 0 ? '' : 'hidden'}`}>
            <div className='flex-auto text-left align-top text-sm'>
              <p className='text-secondaryColor uppercase text-sm'>Existencias por depósitos</p>
            </div>
          </div>

          {existencias.Depositos &&
            existencias.Depositos.map((deposito, index) => {
              return (
                <div key={index} className='flex flex-row align-top font-display font-bold mt-2 pb-2'>
                  <div className='flex-auto text-left align-top text-xs'>
                    <p className='font-bold uppercase'>{deposito.Deposito}</p>
                    <p className='font-semibold'>Existencias: <span className='uppercase text-primaryColor'>{deposito.Existencia}</span></p>
                  </div>
                </div>
              )
            })}
        </div>
      }
      {isStockDetail &&
        <div className='flex flex-row font-display font-bold'>
          <button className='mt-4 bg-primaryColor w-full text-bgColor inline-block rounded shadow-md p-2 px-4 font-bold'
            onClick={() =>
              history.push(
                `/existencias/movimientos?producto=${data?.IdProducto}&subproducto=${data?.IdSubProducto}&deposito=${selectedDeposito}&p=${btoa(data?.Descripcion)}`
              )}
          >
            Ver movimientos
          </button>
        </div>
      }
    </div >
  )
}

export default ExistenciaDetalle
