import { PropTypes, useState } from 'react'
import { useHistory } from "react-router-dom";
import { moneyType, toMoneyFormat } from '../../../Utils/Format'


const CuentaItem = ({ cuenta }) => {
  let history = useHistory();

  return (
    <div className='flex flex-row align-bottom font-display font-bold text-xs shadow p-2'
      onClick={() => history.push(`/clientes/cuentas/${cuenta.Id}?u=${btoa(cuenta.CuentaCliente)}&c=${btoa(cuenta.IdCliente)}&norefresh=true&ai=${cuenta.IdCliente}&ci=${cuenta.Id}`)}>
      <div className='flex-auto text-left align-bottom pr-2 uppercase min-w-min'>
        <p className='uppercase'>{cuenta.CuentaCliente}</p>
        <p className='text-secondaryColor'>{cuenta.Cuenta}</p>
      </div>
      <div className='flex-auto text-right align-bottom'>
        <h1 className='uppercase text-base' style={{ whiteSpace: 'nowrap' }}>
          {moneyType(cuenta.Moneda)} {toMoneyFormat(cuenta.Saldo)}
        </h1>
      </div>
    </div>
  )
}

export default CuentaItem

