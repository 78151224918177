import { PropTypes, useState, useEffect } from 'react'
import Layout from '../../Components/Layout'
import TextBox from '../../Components/TextBox/TextBox'
import { IoPerson, IoKey } from "react-icons/io5";
import { Redirect, useHistory } from 'react-router-dom'
import { useAuth } from "../../Hooks/auth-hook/AuthHook";
import { getUserData, tokenExpired } from "../../Utils/LocalStorage";
import LogoEmpresa from '../../images/logo-empresa.jpg'

const LoginPage = () => {
  const auth = useAuth();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const history = useHistory();

  const handleSubmit = (e) => {
    e.preventDefault();

    let formData = {};

    let err = false;
    if (!username) {
      setError(true);
      err = true;
    }
    if (!password) {
      setError(true);
      err = true;
    }

    if (err) return null;

    formData.username = username;
    formData.password = password;

    auth.signin(formData)
  };

  const handleUsername = (value) => {
    setUsername(value);
  };

  const handlePassword = (value) => {
    setPassword(value);
  };


  if (auth.user && getUserData('token')) {
    return (
      <Redirect to={{ pathname: "/home", state: { from: "/login" } }} />
    );
  }



  return (
    <Layout>
      <div className='flex-auto text-right'>
        <p className='font-display font-bold text-sm'>Abacus mobile</p>
        <p className='font-mono text-xs'>ver {process.env.REACT_APP_VERSION}</p>
      </div>
      <div className='flex-auto w-full my-24'>
        <img className='mx-auto rounded-md max-h-36' src={LogoEmpresa} />
      </div>
      <div className='flex-auto text-left mb-2'>
        <h1 className='font-display font-bold text-lg text-text'>Iniciar sesión</h1>
      </div>
      <form onSubmit={handleSubmit}>
        <div className='flex-auto text-left space-y-3'>
          <TextBox
            name="usuario"
            label=""
            type="text"
            enterhint="next"
            placeholder="Usuario Abacus"
            icon={<IoPerson />}
            onChange={handleUsername}
            required
          />
          <TextBox
            name="Clave Abacus"
            label=""
            type="password"
            enterhint="done"
            placeholder="Nombre de usuario"
            icon={<IoKey />}
            onChange={handlePassword}
            required
          />
        </div>
        <div className='flex-auto text-center'>
          <button to='/home'
            className='block text-center w-full bg-primaryColor text-bgColor text-sm font-display font-bold p-2 rounded-md mt-8 shadow hover:bg-opacity-90 hover:shadow-lg'
          >Ingresar</button>
        </div>
      </form>
    </Layout >
  )
}

export default LoginPage
