import Layout from '../Components/Layout'
import Header from '../Components/Header/Header'
//import Carousel from './Carousel/Carousel'
import { Link } from 'react-router-dom'
import Productos from '../images/productos.svg'
import { canAccess } from '../Utils/LocalStorage'

const ProductosHomePage = () => {
  return (
    <Layout>
      <Header titulo='Movimientos' toAddress='/home' />
      <div className='flex-auto w-full text-center'>
        <div className='w-full flex-none mt-28 text-center font-display px-2' style={{ scrollSnapAlign: 'start' }}>
          <img className='mx-auto rounded-md mb-20' src={Productos} alt='Logo' width='312' height='199'/>
          <h1 className='font-bold text-lg'>Movimientos</h1>
          <Link to='/productos/venta' className={`inline-block text-center w-full bg-primaryColor text-bgColor text-sm font-display font-bold p-2 rounded-md shadow hover:bg-opacity-90 hover:shadow-lg mt-4 ${canAccess('CrearNotaDePedidoVentas') ? '' : 'hidden'}`}>Pedido de venta</Link>
          <Link to='/productos/compra' className={`inline-block text-center w-full bg-primaryColor text-bgColor text-sm font-display font-bold p-2 rounded-md shadow hover:bg-opacity-90 hover:shadow-lg mt-4 ${canAccess('CrearNotaDePedidoCompras') ? '' : 'hidden'}`}>Pedido de compra</Link>
          <Link to='/stock/' className={`inline-block text-center w-full bg-primaryColor text-bgColor text-sm font-display font-bold p-2 rounded-md shadow hover:bg-opacity-90 hover:shadow-lg mt-4 ${(!canAccess('CrearRemitoCompras') && !canAccess('CrearControlDeInventarioStock') && !canAccess('CrearAjusteStock')) ? 'hidden' : ''}`}>Movimientos de stock</Link>
        </div>
      </div>
    </Layout >
  )
}

export default ProductosHomePage
