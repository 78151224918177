import { useEffect, useState } from 'react'
import ComboBox from '../../../Components/ComboBox'
import TextBox from '../../../Components/TextBox/TextBox'
import { useHistory } from 'react-router-dom'
import { addProducto } from '../../../Utils/db'
import { toast } from 'react-toastify'

const EdicionProductoForm = ({ producto, listadoPrecios, tipoPedido }) => {
  const history = useHistory();

  const [precio, setPrecio] = useState(producto?.precio)
  const [cantidad, setCantidad] = useState(producto?.cantidad)
  const [listaPrecios, setListaPrecios] = useState([])
  const [canEdit, setCanEdit] = useState(false)

  useEffect(() => {
    setListaPrecios(producto.listasDePrecios)
    setCantidad(producto?.cantidad)
    setPrecio(producto?.precio)
  }, [listadoPrecios, producto])


  const handleListaPrecios = (lista) => {
    setCanEdit(listadoPrecios.filter(precio => precio.Id == lista.id)[0].PermitirModificarPrecio)
    setListaPrecios(lista)
  }

  const handleEditarProducto = () => {
    let listaEfectiva = listadoPrecios.find(precio => precio.Id === parseInt(listaPrecios.id))
    if (!listaEfectiva) {
      listaEfectiva = producto.listasDePrecios
    }

    addProducto(producto, cantidad, precio, listaEfectiva, tipoPedido)
    toast.success(`${producto.nombre} editado`)
    history.goBack()
  }

  return (
    <div>
      <div className='flex flex-row align-top font-display font-bold mt-1'>
        <div className='flex-auto text-left align-top text-xs w-full'>
          <TextBox
            name="productos"
            label="Cantidad pedido"
            type="number"
            enterhint="next"
            placeholder="Cantidad"
            autocomplete="off"
            value={cantidad}
            onChange={(e) => setCantidad(e)}
          />
        </div>

        <div className={`flex-auto text-left align-top text-xs w-full ml-2 ${tipoPedido === 'compra' ? 'hidden' : ''}`}>
          <TextBox
            name="precio"
            label="Precio"
            type="number"
            enterhint="next"
            placeholder="Precio"
            autocomplete="off"
            value={precio}
            onChange={(val) => setPrecio(val)}
            disabled={!canEdit}
          />
        </div>
      </div>

      <div className={`flex flex-col align-top font-display font-bold mt-1 w-full  ${tipoPedido === 'compra' ? 'hidden' : ''}`}>
        <ComboBox
          name="listaPrecios"
          text="Lista de precios"
          placeholder='Ver listado'
          textFieldName="Nombre"
          value={listaPrecios || {}}
          items={listadoPrecios || {}}
          onChange={(val) => handleListaPrecios(val)}
        />
      </div>

      <div className='mt-4 text-sm flex flex-row align-top font-display'>
        <div className='flex-auto text-center px-2'>
          <button className='bg-secondaryColor w-full text-bgColor inline-block rounded shadow-md p-2 px-4 font-bold'
            onClick={() => { history.goBack() }}>
            Cancelar
          </button>
        </div>
        <div className='flex-auto text-center'>
          <button className='bg-primaryColor w-full text-bgColor inline-block rounded shadow-md p-2 px-4 font-bold'
            onClick={() => { handleEditarProducto() }}
            Guardar
          >
            Actualizar
          </button>
        </div>
      </div>
    </div>
  )
}

export default EdicionProductoForm
