import { useEffect, useState, useMemo } from 'react'
import Layout from '../../Components/Layout'
import Header from '../../Components/Header/Header'
import TextBox from '../../Components/TextBox/TextBox'
import Placeholder from '../../Components/Placeholder/Placeholder'
import { IoPerson, IoSearch } from "react-icons/io5";
import CuentaItem from './CuentaItem/CuentaItem.js'
import axios from 'axios'
import { getTokenHeader, setToken, setUserData } from '../../Utils/LocalStorage'
import { formatFecha } from '../../Utils/Format'
import { useLocation, useHistory } from 'react-router-dom'
import Spinner from '../../Components/Spinner/Spinner'
import { toast } from 'react-toastify'

function useQuery() {
  const { search } = useLocation()
  return useMemo(() => new URLSearchParams(search), [search])
}
const CuentasMainPage = () => {
  const [cliente, setCliente] = useState('')
  const [cuentas, setCuentas] = useState([])
  const [isLoading, setIsLoading] = useState(false)


  let query = useQuery()
  const history = useHistory();
  useEffect(() => {
    window.scrollTo(0, 0);
    if (!query.get('norefresh')) {
      setIsLoading(true)
      axios.get(`${process.env.REACT_APP_ENDPOINT}/ventas/GetSaldosCuentaCorriente?contieneTexto=`, getTokenHeader())
        .then(res => {
          setCuentas(res.data.Result)
          setToken(res.data.RenewedAccessToken, res.data.RenewedAccessTokenExpireTime)
          setIsLoading(false)
          history.push('/clientes/cuentas?norefresh=true')
        })
        .catch(err => {
          toast.error(`Hubo un problema al recuperar la información: ${err.response?.data.ErrorMessage || 'sin conexión'}`, {
            position: toast.POSITION.TOP_CENTER
          })

          setIsLoading(false)
          if (err.response?.status === 401) {
            setUserData();
            history.push('/login')
            setIsLoading(false)
          }
        })
    }
  }, [query, history])

  const buscarCuentas = () => {
    document.activeElement.blur()
    setIsLoading(true)
    axios.get(`${process.env.REACT_APP_ENDPOINT}/ventas/GetSaldosCuentaCorriente?contieneTexto=${cliente}`, getTokenHeader())
      .then(res => {
        setCuentas(res.data.Result)
        setToken(res.data.RenewedAccessToken, res.data.RenewedAccessTokenExpireTime)
        setIsLoading(false)
      })
      .catch(err => {
        toast.error(`Hubo un problema al recuperar la información: ${err.response?.data.ErrorMessage || 'sin conexión'}`, {
          position: toast.POSITION.TOP_CENTER
        })

        setIsLoading(false)
        if (err.response?.status === 401) {
          setUserData();
          history.push('/login')
          setIsLoading(false)
        }
      })
  }

  const handleChange = (e) => {
    if (!e) {
      setCliente('')
      setCuentas([])
    } else {
      setCliente(e)
    }
  }

  return (
    <Layout>
      <Header titulo='Cuentas' subtitulo='' toAddress='/clientes' />

      <div className='mt-14 flex flex-row'>
        <div className='flex-auto w-8/12 pr-1'>
          <TextBox
            name="usuario"
            label=""
            type="search"
            enterhint="next"
            placeholder="Buscar cliente"
            icon={<IoPerson />}
            onChange={(e) => handleChange(e)}
            onEnter={() => buscarCuentas()}
            value={cliente}
          />
        </div>
        <div className='w-2/12 pr-1 hidden'>
          <TextBox
            name="usuario"
            label=""
            type="date"
            enterhint="next"
            placeholder="Buscar cliente"
          />
        </div>
        <div className='w-1/12 text-right mr-3 -mt-0'>
          <button className='rounded-full text-center bg-primaryColor p-3 text-bgColor' onClick={() => buscarCuentas()}> <IoSearch /></button>
        </div>
      </div>
      <div className='flex flex-row mt-2 mb-2 py-2 px-2'>
        <h3 className='text-xs font-display font-bold'>
          {cuentas.length > 0 && `Saldos al día ${formatFecha(Date.now())}`}
        </h3>
      </div>

      {isLoading && <Spinner />}
      {!isLoading &&
        <div className='space-y-3'>
          {
            cuentas.map((cuenta, index) => {
              return <CuentaItem key={index} cuenta={cuenta} />
            })
          }
        </div>
      }
      {cuentas.length === 0 && !isLoading &&
        < div >
          <Placeholder titulo='Sin resultado' texto='La búsqueda no arrojó ningún resultado. Realice una nueva búqueda.' />
        </div>
      }
    </Layout >
  )
}

export default CuentasMainPage

