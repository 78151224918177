import { useState, useMemo } from 'react'
import Layout from '../../../../Components/Layout'
import Header from '../../../../Components/Header/Header'
import BarcodeScannerComponent from "react-webcam-barcode-scanner";
import { useHistory, useLocation, Link } from 'react-router-dom'

import { toast } from 'react-toastify'

function useQuery() {
  const { search } = useLocation()
  return useMemo(() => new URLSearchParams(search), [search])
}

const StockBarcodeReader = ({ tipoPedido, nombre }) => {
  const history = useHistory();
  const [err, setErr] = useState('');

  let query = useQuery()

  const handleScan = (err, data) => {
    if (err) {
      setErr('No se pudo leer el código de barras');
    } else {
      if (data) {
        toast.success(`LEIDO: ${data.text}`)
        history.push(`/stock/${tipoPedido}?barcode=${data.text}&depositoId=${query.get('depositoId')}`)
      }
    }
  }


  return (
    <Layout>
      <Header titulo={nombre} toAddress={`/stock/${tipoPedido}?norefresh=true&depositoId=${query.get('depositoId')}`} />
      <dummy>
        <BarcodeScannerComponent className={`inline-block text-center w-full bg-primaryColor text-bgColor text-sm font-display font-bold p-2 rounded-md shadow hover:bg-opacity-90 hover:shadow-lg mt-4 `}
          width={400}
          height={100}
          onUpdate={(err, result) => {
            handleScan(err, result)
          }}
        />
      </dummy>

    </Layout >
  )
}

export default StockBarcodeReader



