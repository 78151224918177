import React, { useState, useEffect, useContext, createContext } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { getUserData, setUserData, setToken } from "../../Utils/LocalStorage";
import { toast } from 'react-toastify'

const authContext = createContext();

export const ProvideAuth = ({ children }) => {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};

export const useAuth = () => useContext(authContext);

function useProvideAuth() {
  const [user, setUser] = useState("");
  const [loginError, setLoginError] = useState(false);

  const history = useHistory();

  const signin = (credentials) => {
    axios
      .post(`${process.env.REACT_APP_ENDPOINT}/usuarios/Login?user=${credentials.username}&pass=${credentials.password}`, credentials)
      .then((data) => {
        setLoginError(false);
        setUser(data.data.AccessToken);
        setUserData(data.data);
        setToken(data.data.AccessToken, data.data.AccessTokenExpireTime);
        history.push("/home");
      })
      .catch((err) => {
        setLoginError(true);

        if (err.toString().startsWith("Error: Network Error")) {
          toast.error('Sin conexión', {
            position: toast.POSITION.TOP_CENTER
          })
        } else {
          toast.error(err.response?.data.ErrorMessage, {
            position: toast.POSITION.TOP_CENTER
          })
        }
      });
  };

  useEffect(() => {
    // Cargar los datos si está logueado
    if (getUserData('token')) {
      setUser(getUserData('token'));
    } else {
      setUser("");
    }
    setLoginError("El token expiró");
  }, []);

  return { user, signin, loginError };
}
