import Layout from '../Components/Layout'
import Header from '../Components/Header/Header'
import { Link } from 'react-router-dom'
import Clientes from '../images/clientes.svg'

const CuentasHomePage = () => {
  return (
    <Layout>
      <Header titulo='Clientes' toAddress='/home' />
      <div className='flex-auto w-full text-center'>
        <div className='w-full flex-none mt-24 text-center font-display px-2' style={{ scrollSnapAlign: 'start' }}>
          <img className='mx-auto rounded-md mb-4' src={Clientes} alt='Logo' width='312' height='204' />
          <h1 className='font-bold text-lg'>Clientes</h1>
          <ul className='text-sm text-secondaryColor mb-4'>
            <li>Movimientos y saldos</li>
            <li>Comprobantes</li>
          </ul>
          <Link to='/clientes/cuentas' className='inline-block text-center w-full bg-primaryColor text-bgColor text-sm font-display font-bold p-2 rounded-md shadow hover:bg-opacity-90 hover:shadow-lg mt-4'>ver cuentas</Link>
        </div>
      </div>
    </Layout >
  )
}

export default CuentasHomePage
