import './App.css';
import LoginPage from './LoginStack/LoginPage/LoginPage'
import HomePage from './HomeStack/HomePage/HomePage'
import CuentasHomePage from './CuentasStack/CuentasHome'
import CuentasPage from './CuentasStack/CuentasMainPage/CuentasMainPage'
import CuentasDetallePage from './CuentasStack/CuentasDetallePage/CuentasDetallePage'
import ExistenciasHomePage from './ExistenciasStack/ExistenciasHome'
import ExistenciasBusqueda from './ExistenciasStack/ExistenciasBusqueda/ExistenciasBusqueda'
import ExistenciasMovimientos from './ExistenciasStack/ExistenciaMovimientos/ExistenciaMovimientos'

import ProductosHomePage from './ProductosStack/ProductosHome'
import ProductosPage from './ProductosStack/ProductosHomePage/ProductosHomePage'
import CheckoutPage from './ProductosStack/CheckoutPage/CheckoutPage'
import CheckoutFinishPage from './ProductosStack/CheckoutFinishPage/CheckoutFinishPage'

import StockHome from './ProductosStack/StockStack/StockHome'
import StockMainPage from './ProductosStack/StockStack/StockMainPage/StockMainPage'
import StockBarcode from './ProductosStack/StockStack/StockMainPage/StockBarcodeReader/StockBarcodeReader'
import CheckoutStockFinishPage from './ProductosStack/StockStack/StockMainPage/StockCheckoutFinishPage/StockCheckoutFinishPage'
import CheckoutStockPage from './ProductosStack/StockStack/StockMainPage/StockCheckoutPage/StockCheckoutPage'

import { ToastContainer, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';

import {
  ProvideAuth,
  useAuth,
} from "./Hooks/auth-hook/AuthHook";

const PrivateRoute = ({ children, ...rest }) => {
  const auth = useAuth();
  return (
    <Route
      {...rest}
      render={({ location }) =>
        auth.user ? (
          children
        ) : (
          <Redirect to={{ pathname: "/login", state: { from: location } }} />
        )
      }
    />
  );
};

function App() {
  return (
    <ProvideAuth>
      <Router>
        <Switch>
          <Route path="/login">
            <LoginPage />
          </Route>
          <PrivateRoute path="/home">
            <HomePage />
          </PrivateRoute>

          <PrivateRoute path="/stock/compras/checkout/finalizar">
            <CheckoutStockFinishPage tipoPedido='compras' nombre='Remito de compras' />
          </PrivateRoute>
          <PrivateRoute path="/stock/compras/checkout">
            <CheckoutStockPage tipoPedido='compras' nombre='Remito de compras' />
          </PrivateRoute>
          <PrivateRoute path="/stock/compras/barcode">
            <StockBarcode tipoPedido='compras' nombre='Remito de compras' />
          </PrivateRoute>
          <PrivateRoute path="/stock/compras">
            <StockMainPage tipoPedido='compras' nombre='Remito de compras' />
          </PrivateRoute>
          <PrivateRoute path="/stock/control/checkout/finalizar">
            <CheckoutStockFinishPage tipoPedido='control' nombre='Control de inventario' />
          </PrivateRoute>
          <PrivateRoute path="/stock/control/barcode">
            <StockBarcode tipoPedido='control' nombre='Control de stock' />
          </PrivateRoute>
          <PrivateRoute path="/stock/control">
            <StockMainPage tipoPedido='control' nombre='Control de stock' />
          </PrivateRoute>
          <PrivateRoute path="/stock/ajuste/checkout/finalizar">
            <CheckoutStockFinishPage tipoPedido='ajuste' nombre='Ajuste de stock' />
          </PrivateRoute>
          <PrivateRoute path="/stock/ajuste/barcode">
            <StockBarcode tipoPedido='ajuste' nombre='Ajuste de stock' />
          </PrivateRoute>
          <PrivateRoute path="/stock/ajuste">
            <StockMainPage tipoPedido='ajuste' nombre='Ajuste de stock' />
          </PrivateRoute>
          <PrivateRoute path="/stock">
            <StockHome />
          </PrivateRoute>

          <PrivateRoute path="/clientes/cuentas/:id">
            <CuentasDetallePage />
          </PrivateRoute>
          <PrivateRoute path="/clientes/cuentas">
            <CuentasPage />
          </PrivateRoute>
          <PrivateRoute path="/clientes">
            <CuentasHomePage />
          </PrivateRoute>

          <PrivateRoute path="/productos/compra/checkout/finalizar">
            <CheckoutFinishPage tipoPedido='compra' />
          </PrivateRoute>
          <PrivateRoute path="/productos/compra/checkout">
            <CheckoutPage tipoPedido='compra' />
          </PrivateRoute>
          <PrivateRoute path="/productos/compra">
            <ProductosPage tipoPedido='compra' />
          </PrivateRoute>
          <PrivateRoute path="/productos/venta/checkout/finalizar">
            <CheckoutFinishPage tipoPedido='venta' />
          </PrivateRoute>
          <PrivateRoute path="/productos/venta/checkout">
            <CheckoutPage tipoPedido='venta' />
          </PrivateRoute>
          <PrivateRoute path="/productos/venta">
            <ProductosPage tipoPedido='venta' />
          </PrivateRoute>
          <PrivateRoute path="/productos">
            <ProductosHomePage />
          </PrivateRoute>

          <PrivateRoute path="/existencias/movimientos">
            <ExistenciasMovimientos />
          </PrivateRoute>
          <PrivateRoute path="/existencias/buscar">
            <ExistenciasBusqueda />
          </PrivateRoute>
          <PrivateRoute path="/existencias">
            <ExistenciasHomePage />
          </PrivateRoute>
          <PrivateRoute path="/">
            <Redirect to={{ pathname: "/login" }} />
          </PrivateRoute>
        </Switch>
      </Router>
      <ToastContainer
        position='top-center'
        autoClose={2000}
        theme='dark'
        transition={Slide}
      />
    </ProvideAuth>
  );
}

export default App;
