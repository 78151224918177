import { useMemo, PropTypes, useState, useEffect } from 'react'
import Layout from '../../Components/Layout.js'
import Header from '../../Components/Header/Header'
import TextBox from '../../Components/TextBox/TextBox'
import Placeholder from '../../Components/Placeholder/Placeholder'
import ComboBox from '../../Components/ComboBox'
import axios from 'axios'
import { getTokenHeader, getUserData } from '../../Utils/LocalStorage'
import { formatFechaISO } from '../../Utils/Format'
import { addDays } from '../../Utils/Utils'
import { useLocation, useHistory } from 'react-router-dom'
//import Lista from './CuentasDetalleLista/CuentasDetalleLista'
//import ListaComprobantes from './CuentasDetalleListaComprobantes/CuentasDetalleListaComprobantes'
import DetalleOverlay from '../../Components/DetalleOverlay/DetalleOverlay'
//import CuentaDetalle from './CuentasDetalle/CuentasDetalle'
import ProductoItem from './ExistenciasItem.js'

import { toast } from 'react-toastify'

function useQuery() {
  const { search } = useLocation()
  return useMemo(() => new URLSearchParams(search), [search])
}

const ExistenciaMovimientos = ({ titulo, subtitulo }) => {
  const [deposito, setDeposito] = useState({})
  const [listaDepositos, setListaDepositos] = useState([])
  const [movimientos, setMovimientos] = useState([{}])

  const [showDetalle, setShowDetalle] = useState(false)
  const [detalle, setDetalle] = useState({})

  const [queryData, setQueryData] = useState([])


  const [desde, setDesde] = useState(formatFechaISO(addDays(Date.now(), -30)))
  const [hasta, setHasta] = useState(formatFechaISO(addDays(Date.now(), 0)))
  const [isLoading, setIsLoading] = useState(false)

  let query = useQuery()
  const history = useHistory();


  // Inizializacion de datos
  useEffect(() => {
    axios.get(`${process.env.REACT_APP_ENDPOINT}/stock/GetDepositos`, getTokenHeader())
      .then(res => {
        // Insert an element TODOS in the first position
        let result = res.data.Result
        result.unshift(
          {
            Id: 0,
            Nombre: 'TODOS',
            IdSucursal: 0,
            Sucursal: "Test"
          })

        let depo = result.find(x => x.Id === parseInt(query.get('deposito'))) || result[0]
        setDeposito(depo)
        setListaDepositos(result)

        axios.get(`${process.env.REACT_APP_ENDPOINT}/stock/GetMovimientosPorProducto` +
          `?fechaDesde=${desde}` +
          `&fechaHasta=${hasta}` +
          `&idProducto=${query.get('producto')}` +
          `&idSubProducto=${query.get('subproducto')}` +
          `&idDeposito=${query.get('deposito')}`, getTokenHeader())
      })
      .then(res => {
        setMovimientos(res.data.Result)
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => {
        setIsLoading(false)
      })

    axios.get(`${process.env.REACT_APP_ENDPOINT}/stock/GetMovimientosPorProducto` +
      `?fechaDesde=${desde}` +
      `&fechaHasta=${hasta}` +
      `&idProducto=${query.get('producto')}` +
      `&idSubProducto=${query.get('subproducto')}` +
      `&idDeposito=${deposito.id}`, getTokenHeader())
      .then(res => {
        setMovimientos(res.data.Result)
      })
      .catch(err => {
        console.log(err)
        toast.error(err.response.data.ErrorMessage)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [])

  const handleChange = (field, value) => {
    let pDesde = field === 'desde' ? value : desde
    let pHasta = field === 'hasta' ? value : hasta
    let pDeposito = field === 'deposito' ? value : deposito

    switch (field) {
      case 'desde':
        setDesde(value)
        break
      case 'hasta':
        setHasta(value)
        break
      case 'deposito':
        setDeposito(value)
        break
      default:
        break
    }
    axios.get(`${process.env.REACT_APP_ENDPOINT}/stock/GetMovimientosPorProducto` +
      `?fechaDesde=${pDesde}` +
      `&fechaHasta=${pHasta}` +
      `&idProducto=${query.get('producto')}` +
      `&idSubProducto=${query.get('subproducto')}` +
      `&idDeposito=${pDeposito.id}`, getTokenHeader())
      .then(res => {
        setMovimientos(res.data.Result)
      })
      .catch(err => {
        console.log(err)
        toast.error(err.response.data.ErrorMessage)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }


  // Handler para mostrar el detalle de un movimiento
  const handleShowDetalle = (objectKey) => {
    axios.get(`${process.env.REACT_APP_ENDPOINT}/comprobantes/GetDetalleComprobante?objectKey=${objectKey}`, getTokenHeader()).then(res => {
      let det = res.data
      det.objectKey = objectKey
      setDetalle(det)
      history.push(`/clientes/cuentas/detalle/${objectKey}?openDetail=o`)
    })
  }


  return (
    <Layout>
      // <Header titulo={atob(query.get('u'))} subtitulo='Movimientos' toAddress='/clientes/cuentas?norefresh=true' />
      <Header
        titulo={'MOVIMIENTO'}
        subtitulo={atob(query.get('p'))}
        toAddress='/existencias/buscar?norefresh=true'
      />


      <div className='mt-14 flex flex-row'>
        <div className='flex-auto pr-1'>
          <ComboBox
            name="listaDepositos"
            text="Deposito"
            placeholder='Ver listado'
            textFieldName="Nombre"
            value={deposito}
            items={listaDepositos}
            onChange={(val) => handleChange('deposito', val)}
          />
        </div>
      </div>
      <div className='mt-2 flex flex-row'>
        <div className='flex-auto w-4/12 pr-1'>
          <TextBox
            name="desde"
            label="Desde"
            type="date"
            enterhint="next"
            placeholder="Buscar movimientos"
            value={desde}
            onChange={(val) => { handleChange('desde', val) }}
          />
        </div>
        <div className='flex-auto w-4/12 pr-1'>
          <TextBox
            name="hasta"
            label="Hasta"
            type="date"
            enterhint="next"
            placeholder="Buscar movimientos"
            value={hasta}
            onChange={(val) => { handleChange('hasta', val) }}
          />
        </div>
      </div>

      <table className='mt-4 table font-display'>

        {movimientos[0]?.Existencia &&
          <div className="flex flex-row font-normal font-display mb-3 border-b-2">
            <span className="w-full font-bold uppercase text-sm">
              Existencia a la fecha
            </span>
            <span className="text-right uppercase text-base font-bold">
              {movimientos[0].Existencia}
            </span>
          </div>
        }

        {
          movimientos.map((movimiento, index) => {
            return <ProductoItem item={movimiento} />
          })
        }
      </table>
      {movimientos.length === 0 && !isLoading &&
        < div >
          <Placeholder titulo='Sin resultado' texto='No se encontraron movimientos en ese período de tiempo. Realice una nueva búqueda.' />
        </div>
      }


      <DetalleOverlay
        show={showDetalle}
        titulo={`Detalle de `}
        onClose={() => { history.goBack() }}>

      </DetalleOverlay>
    </Layout >
  )
}

export default ExistenciaMovimientos
