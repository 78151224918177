import { useEffect, useState, useMemo } from 'react'
import Layout from '../../Components/Layout'
import Header from '../../Components/Header/Header'
import TextBox from '../../Components/TextBox/TextBox'
import Placeholder from '../../Components/Placeholder/Placeholder'
import ProductoItem from '../ProductosItem/ProductosItem'
import DetalleOverlay from '../../Components/DetalleOverlay/DetalleOverlay'
import ProductoDetalle from '../ProductoOrdenDetalle/ProductoOrdenDetalle'
import { getTokenHeader, setToken } from '../../Utils/LocalStorage'
import axios from 'axios'

import { Link, useLocation, useHistory } from 'react-router-dom'
import { getProducto, getTotalProductos } from '../../Utils/db'
import Spinner from '../../Components/Spinner/Spinner'

import { IoSearch, IoChevronUp, IoCart, IoPricetags } from "react-icons/io5";
import { toast } from 'react-toastify'

function useQuery() {
  const { search } = useLocation()
  return useMemo(() => new URLSearchParams(search), [search])
}


const ProductosMainPage = ({ tipoPedido }) => {
  const [producto, setProducto] = useState('')
  const [showDetalle, setShowDetalle] = useState(false)
  const [detalle, setDetalle] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [isModalLoading, setIsModalLoading] = useState(false)
  const [listaPrecios, setListaPrecios] = useState({})

  const [moveTop, setMoveTop] = useState(false)


  const [productos, setProductos] = useState([])
  const [cantidad, setCantidad] = useState(0)
  const [precio, setPrecio] = useState(0)
  const [existencias, setExistencias] = useState([])

  const [totalProductos, setTotalProductos] = useState(0)

  let query = useQuery()
  const history = useHistory();

  const cancelTokenSource = axios.CancelToken.source();

  useEffect(() => {
    getTotalProductos(tipoPedido).then(res => {
      setTotalProductos(res)
      if (!query.get('norefresh')) {
        window.scrollTo(0, 0);
        history.push(`/productos/${tipoPedido}/?norefresh=true`)
      }
    })
  })

  const handleProductoChange = (val) => {
    setProducto(val)
    if (val === '') {
      setProductos([])
    }
  }

  // Handler para mostrar el detalle de un movimiento
  const handleShowDetalle = (producto) => {
    cancelTokenSource.cancel('Reload');
    if (tipoPedido === 'venta') {
      setShowDetalle(true)
      setDetalle(producto)
      getProducto(producto.IdProducto, tipoPedido).then(res => {
        setCantidad(res?.cantidad || 0)
        setPrecio(res?.precio ? res.precio : producto.PrecioVenta ? producto.PrecioVenta : 0)
        axios.get(`${process.env.REACT_APP_ENDPOINT}/stock/GetListasDePrecioProducto?idProducto=${producto.IdProducto}`, getTokenHeader(), { cancelToken: cancelTokenSource, progress: false }).then(res => {
          setListaPrecios(res.data.Result)
          setToken(res.data.RenewedAccessToken, res.data.RenewedAccessTokenExpireTime)
          history.push(`/productos/${tipoPedido}?norefresh=true&openDetail=o&tipo=${tipoPedido}`)
        })
          .catch(err => {
            console.log(err)
            if (err?.response?.status === 401) {
              setToken('')
              window.location.href = '/login'
              setIsModalLoading(false)
            } else {
              setIsModalLoading(false)
              toast.error('Ocurrió un error al obtener la información del producto')
            }
          })
      })
    } else {
      setShowDetalle(true)
      setDetalle(producto)
      getProducto(producto.IdProducto, tipoPedido).then(res => {
        setCantidad(res?.cantidad || 0)
        setPrecio(res?.precio ? res.precio : producto.PrecioVenta ? producto.PrecioVenta : 0)
      })
      history.push(`/productos/${tipoPedido}?norefresh=true&openDetail=o&tipo=${tipoPedido}`)
    }
  }

  const buscarProductos = () => {
    document.activeElement.blur()
    setIsLoading(true)
    axios.get(`${process.env.REACT_APP_ENDPOINT}/stock/GetExistencias?idCategoria=0&contieneTexto=${producto}&incluirProductosSinExistencia=true`, getTokenHeader())
      .then(res => {
        if (res.data.Result.length > 0) {
          setProductos(res.data.Result)
        } else {
          setProductos([])
        }
        setToken(res.data.RenewedAccessToken, res.data.RenewedAccessTokenExpireTime)
        setIsLoading(false)
      })
      .catch(err => {
        toast.error(`Hubo un problema al recuperar la información: ${err.response?.data.ErrorMessage || 'sin conexión'}`, {
          position: toast.POSITION.TOP_CENTER
        })

        setIsLoading(false)
        if (err.response?.status === 401) {
          setToken('')
          window.location.href = '/login'
        }
      })
  }


  const handleScroll = () => {
    if (document.body.getBoundingClientRect().top < -30) {
      setMoveTop(true)
    } else {
      setMoveTop(false)
    }
  }

  return (
    <Layout>
      <Header titulo={`Pedido de ${tipoPedido}`} subtitulo='' toAddress='/productos' />

      <form className='mt-14 flex flex-col space-y-2'>
        <div className='flex flex-row'>
          <div className='flex-auto w-auto'>
            <TextBox
              name="producto"
              label=""
              type="search"
              enterhint="done"
              placeholder="Filtrar producto"
              icon={<IoPricetags />}
              value={producto}
              onEnter={() => buscarProductos()}
              onChange={(val) => handleProductoChange(val)}
              autocomplete="off"
            />
          </div>
          <div className='w-1/12 text-right ml-1 mr-3 -mt-0'>
            <button type='button' className='rounded-full text-center bg-primaryColor p-3 text-bgColor' onClick={() => buscarProductos()}> <IoSearch /></button>
          </div>
        </div>
      </form>

      {isLoading && <Spinner />}
      {!isLoading &&
        <table className='mt-4 table mb-4' onWheel={() => handleScroll()} onTouchMove={() => handleScroll()}>
          {productos.map((item, key) => (
            <ProductoItem data={item}
              tipoPedido={tipoPedido}
              onClick={(val) => { handleShowDetalle(val) }}
            />
          ))
          }
        </table>
      }
      {productos.length === 0 && !isLoading &&
        < div >
          <Placeholder titulo='Sin resultado' texto='La búsqueda no arrojó ningún resultado. Realice una nueva búqueda.' />
        </div>
      }

      <Link to={`/productos/${tipoPedido}/checkout`} className={`fixed p-4 inline rounded-full bg-primaryColor bottom-16 left-6 shadow-lg ${totalProductos ? 'block' : 'hidden'}`}>
        <IoCart className='text-2xl text-bgColor' />
        <span className={`absolute text-bgColor bg-textColor rounded-full -right-1 -top-2 p-1 font-bold ${totalProductos ? 'block' : 'hidden'}`} style={{ fontSize: ".7rem" }}>{totalProductos}</span>
      </Link>


      <button className={`fixed p-2 inline rounded-full bg-secondaryColor bottom-16 right-6 shadow-lg ${moveTop ? 'block' : 'hidden'}`} onClick={() => {
        window.scrollTo(0, 0);
      }}>
        <IoChevronUp className='text-2xl text-bgColor' />
      </button>


      <DetalleOverlay
        show={showDetalle}
        titulo={`Detalle de producto`}
        onClose={() => { history.goBack() && setExistencias(null) }}>

        <ProductoDetalle data={detalle} pedidos={cantidad} precio={precio} existencias={existencias} listadoPrecios={listaPrecios} isLoading={isModalLoading} tipoPedido={tipoPedido}
        />
      </DetalleOverlay>
    </Layout >
  )
}

export default ProductosMainPage


