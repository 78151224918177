import { useEffect, useState, useMemo } from 'react'
import Layout from '../../../../Components/Layout'
import Header from '../../../../Components/Header/Header'
import TextBox from '../../../../Components/TextBox/TextBox'
import ProductoItem from '../StockItemCart/StockItemCart'
import { deleteStock, deleteStockItem, getTotalStock } from '../../../../Utils/db'
import { getTokenHeader, setToken } from '../../../../Utils/LocalStorage'
import { formatFechaISO } from '../../../../Utils/Format'
import axios from 'axios'
import { toast } from 'react-toastify'
import DetalleOverlay from '../../../../Components/DetalleOverlay/DetalleOverlay'
import EdicionProductoForm from './EdicionStockForm/EdicionStockForm'

import { useLocation, useHistory } from 'react-router-dom'


const CheckoutFinishPage = ({ tipoPedido, nombre }) => {
  function useQuery() {
    const { search } = useLocation()
    return useMemo(() => new URLSearchParams(search), [search])
  }
  let query = useQuery()
  const history = useHistory();

  const [showDetalle, setShowDetalle] = useState(false)
  const [depositoId, setDepositoId] = useState()
  const [pedido, setPedido] = useState([])
  const [itemSeleccionado, setItemSeleccionado] = useState([])
  const [puesto, setPuesto] = useState()
  const [comprobante, setComprobante] = useState()
  const [proveedorId, setProveedorId] = useState()
  const [fechaRemito, setFechaRemito] = useState(formatFechaISO(new Date()))

  useEffect(() => {
    if (tipoPedido === 'compras') {
      setProveedorId(parseInt(query.get('pid')))
    }
    getTotalStock(tipoPedido).then(data => {
      setPedido(data)
      if (data.length === 0) {
        history.push('/stock')
      }

      if (tipoPedido === 'control') {
        setDepositoId(data[0].IdDeposito)
      }
      axios.get(`${process.env.REACT_APP_ENDPOINT}/stock/GetDepositos`, getTokenHeader())
        .then(res => {
          setListaDepositos(res.data.Result)
        })
        .catch(err => {
          console.log(err)
        })
    })
  }, [tipoPedido])

  const handleEliminarPedido = () => {
    if (window.confirm('¿Realmente desea descartar el movimiento?')) {
      deleteStock(tipoPedido)
      toast.success('Pedido eliminado')
      history.push('/stock')
    }
  }


  const [notas, setNotas] = useState('')
  const [listaDepositos, setListaDepositos] = useState([])

  const handleEditarProducto = (productoSeleccionado) => {
    setItemSeleccionado(productoSeleccionado)
    setShowDetalle(true)
    history.push(`?norefresh=true&openDetail=o`)
  }

  const handleEnviarPedido = () => {
    if (window.confirm('¿Realmente desea enviar el movimiento?')) {
      getTotalStock(tipoPedido).then(data => {
        let pedido = {}
        let endpoint = ''
        switch (tipoPedido) {
          case 'ajuste':
            pedido.IdCuentaProveedor = 0
            pedido.Notas = notas
            pedido.Items = data.map(item => {
              return {
                IdProducto: item.Id,
                IdSubProducto: item.IdSubproducto,
                IdDeposito: item.IdDeposito,
                Cantidad: item.Cantidad,
              }
            })
            endpoint = `${process.env.REACT_APP_ENDPOINT}/Stock/CreateAjuste`
            break
          case 'control':
            pedido.IdCuentaProveedor = 0
            pedido.Notas = notas
            pedido.IdDeposito = data[0].IdDeposito
            pedido.Items = data.map(item => {
              return {
                IdProducto: item.Id,
                IdSubProducto: item.IdSubproducto,
                CantidadReal: item.Cantidad,
              }
            })
            endpoint = `${process.env.REACT_APP_ENDPOINT}/Stock/CreateControlDeInventario`
            break
          case 'compras':
            pedido.Fecha = formatFechaISO(fechaRemito)
            pedido.IdCuentaProveedor = proveedorId
            pedido.Notas = notas
            pedido.NroPuestoVenta = parseInt(puesto)
            pedido.NroComprobante = parseInt(comprobante)
            pedido.Items = data.map(item => {
              return {
                IdProducto: item.Id,
                IdSubProducto: item.IdSubproducto,
                IdDeposito: item.IdDeposito,
                Cantidad: item.Cantidad,
              }
            })
            endpoint = `${process.env.REACT_APP_ENDPOINT}/Compras/CreateRemito`
            break
          default:
            console.log(tipoPedido)
            break
        }
        console.log(pedido)
        axios.post(endpoint, pedido, getTokenHeader())
          .then(res => {
            toast.success(`${res.data.Result}`)
            deleteStock(tipoPedido)
            setToken(res.data.RenewedAccessToken, res.data.RenewedAccessTokenExpireTime)
            history.push('/stock')
          })
          .catch(err => {
            toast.error(err.response.data.ErrorMessage)
          })
      })
    }
  }

  const handleItemDelete = (producto) => {
    deleteStockItem(producto._id).then(() => {
      toast.success('Producto eliminado')
      getTotalStock(tipoPedido).then(data => {
        setPedido(data)
      })
    })
  }

  useEffect(() => {
  }, [history, query, tipoPedido])


  return (
    <Layout>
      <Header titulo={nombre} toAddress={tipoPedido === 'compras' ? `/stock/${tipoPedido}/checkout` : tipoPedido === 'control' ? `/stock/${tipoPedido}?depositoId=${depositoId}` : `/stock/${tipoPedido}`} />

      <div className={`flex flex-row align-top font-display font-bold mt-14 ${tipoPedido === 'compras' ? 'mt-14' : 'hidden'}`}>
        <div className='flex-auto text-left align-top text-xs w-full'>
          <TextBox
            name="productos"
            label="Puesto"
            type="number"
            enterhint="next"
            placeholder="Número de puesto"
            autocomplete="off"
            value={puesto}
            onChange={(val) => setPuesto(val)}
          />
        </div>
        <div className={`flex-auto text-left align-top text-xs w-full ml-2`}>
          <TextBox
            name="precio"
            label="Nº de comprobante"
            type="number"
            enterhint="next"
            placeholder="Número de comprobante"
            autocomplete="off"
            value={comprobante}
            onChange={(val) => setComprobante(val)}
          />
        </div>
      </div>
      <div className={`flex flex-row w-full ${tipoPedido === 'compras' ? 'mt-3' : 'hidden'}`}>
        <div className='flex-auto w-8/12 pr-1'>
          <TextBox
            className='w-full'
            name="fechaRemito"
            label="Fecha del remito"
            type="date"
            enterhint="next"
            placeholder="Ingresar la fecha del remito"
            value={fechaRemito}
            onChange={(val) => { setFechaRemito(val) }}
          />
        </div>
      </div>
      <div className={`flex flex-row ${tipoPedido === 'compras' ? 'mt-3' : 'mt-14'}`}>
        <div className='flex-auto w-8/12 pr-1'>
          <TextBox
            className='w-full'
            name="productos"
            label="Notas (opcional)"
            type="text"
            enterhint="next"
            placeholder="Ingresar una nota interna"
            autocomplete="off"
            multiline={true}
            lines={3}
            value={notas}
            onChange={(e) => setNotas(e)}
          />
        </div>
      </div>


      <table className='mt-4 table'>
        {pedido?.map((item, key) => (
          <ProductoItem key={key} producto={item} onDelete={(producto) => handleItemDelete(producto)} tipoPedido={tipoPedido} onDetalle={(producto) => handleEditarProducto(producto)} showButton={true} listaDepositos={listaDepositos} />
        ))}
      </table>


      <div className={`flex flex-row mt-10 ${pedido.length > 0 ? '' : 'hidden'}`}>
        <button to='/productos' className='inline-block text-center w-full bg-secondaryColor text-bgColor text-sm font-display font-bold p-2 rounded-md shadow hover:bg-opacity-90 hover:shadow-lg mt-4 mr-1' onClick={() => { handleEliminarPedido() }}>Descartar</button>
        <button to='/cuentas' className='inline-block text-center w-full bg-primaryColor text-bgColor text-sm font-display font-bold p-2 rounded-md shadow hover:bg-opacity-90 hover:shadow-lg mt-4 ml-1' onClick={() => handleEnviarPedido()}>Enviar</button>
      </div>

      <DetalleOverlay
        show={showDetalle}
        titulo={`Edición del producto`}
        onClose={() => { history.goBack() }}>

        <EdicionProductoForm producto={itemSeleccionado} tipoPedido={tipoPedido} listaDepositos={listaDepositos} />

      </DetalleOverlay>
    </Layout >
  )
}

export default CheckoutFinishPage

