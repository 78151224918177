import { useState, useEffect } from 'react'
import Layout from '../../../../Components/Layout'
import Header from '../../../../Components/Header/Header'
import TextBox from '../../../../Components/TextBox/TextBox'
import { IoPerson, IoSearch } from "react-icons/io5";
import { getTokenHeader, setToken } from '../../../../Utils/LocalStorage'
import { deleteStock, getTotalStock, deleteStockItem } from '../../../../Utils/db'
import Spinner from '../../../../Components/Spinner/Spinner'
import { toast } from 'react-toastify'
import ProductoItem from '../StockItemCart/StockItemCart'

import { useHistory } from 'react-router-dom'
import axios from 'axios'

const CheckoutPage = ({ tipoPedido }) => {
  const handleEliminarPedido = () => {
    if (window.confirm('¿Realmente desea descartar el movimiento?')) {
      deleteStock(tipoPedido)
      toast.success('Pedido eliminado')
      history.push(`/stock/${tipoPedido}`)
    }
  }

  const handleItemDelete = (producto) => {
    deleteStockItem(producto._id).then(() => {
      toast.success('Producto eliminado')
      getTotalStock(tipoPedido).then(data => {
        setProductos(data)
      })
    })
  }


  const history = useHistory();
  const [actores, setActores] = useState([])
  const [query, setQuery] = useState('')
  const [productos, setProductos] = useState([])
  const [listaDepositos, setListaDepositos] = useState([])

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    getTotalStock(tipoPedido).then(productos => {
      setProductos(productos)
      axios.get(`${process.env.REACT_APP_ENDPOINT}/stock/GetDepositos`, getTokenHeader())
        .then(res => {
          setListaDepositos(res.data.Result)
        })
        .catch(err => {
          console.log(err)
        })
    })
  }, [tipoPedido])

  const handleBuscar = () => {
    document.activeElement.blur()
    setLoading(true)
    let endpoint = `${process.env.REACT_APP_ENDPOINT}/Compras/GetCuentasProveedor?contieneTexto=${query}`
    axios.get(endpoint, getTokenHeader())
      .then(res => {
        setLoading(false)
        setActores(res.data.Result)
        setToken(res.data.RenewedAccessToken, res.data.RenewedAccessTokenExpireTime)
      })
      .catch(err => {
        setLoading(false)
        setToken('')
        console.log(err)
      })
  }


  return (
    <Layout>
      <Header titulo={`Pedido de ${tipoPedido}`} toAddress={`/productos/${tipoPedido}?norefresh=true`} />
      <div className='mt-14 flex flex-row'>
        <div className='flex-auto w-8/12 pr-1'>
          <TextBox
            name="cliente"
            label=""
            type="search"
            enterhint="next"
            placeholder={`Buscar ${tipoPedido === 'venta' ? 'cliente' : 'proveedor'}`}
            icon={<IoPerson />}
            value={query}
            onChange={(e) => setQuery(e)}
            onEnter={handleBuscar}
          />
        </div>
        <div className='w-1/12 text-right mr-3 -mt-0'>
          <button className='rounded-full text-center bg-primaryColor p-3 text-bgColor' onClick={handleBuscar}> <IoSearch /></button>
        </div>
      </div>

      {loading && <Spinner />}
      {!loading &&
        <div className='space-y-3 mt-4'>
          {actores.map((item, key) => {
            return (
              <div key={key} className='uppercase font-display font-bold text-sm p-2 shadow rounded'
                onClick={() => { history.push(`/stock/${tipoPedido}/checkout/finalizar?pid=${item.Id}`) }}>
                <div><p>{item.CuentaCliente ? item.CuentaCliente : item.RazonSocial}</p></div>
              </div>
            )
          })}
        </div>
      }
      <h3 className='font-display font-bold mt-4'>Resumen</h3>
      <table className='mt-2 table'>
        {productos?.map((item, key) => (
          <ProductoItem producto={item} onDelete={(producto) => handleItemDelete(producto)} tipoPedido={tipoPedido} listaDepositos={listaDepositos} showButton={false} />
        ))}
      </table>
      <div className={`flex flex-row mt-4 ${productos.length > 0 ? '' : 'hidden'}`}>
        <button to='/productos' className='inline-block text-center w-full bg-secondaryColor text-bgColor text-sm font-display font-bold p-2 rounded-md shadow hover:bg-opacity-90 hover:shadow-lg mt-4 mr-1' onClick={() => { handleEliminarPedido() }}>Descartar pedido</button>
      </div>


    </Layout >
  )
}

export default CheckoutPage
