import { useEffect, useState } from 'react'
import ComboBox from '../../../../../Components/ComboBox'
import TextBox from '../../../../../Components/TextBox/TextBox'
import { useHistory } from 'react-router-dom'
import { addStock } from '../../../../../Utils/db'
import { toast } from 'react-toastify'

const EdicionProductoForm = ({ producto, listaDepositos, tipoPedido }) => {
  const history = useHistory();

  const [cantidad, setCantidad] = useState(producto.Cantidad)
  const [deposito, setDeposito] = useState({})
  const [tipoAjuste, setTipoAjuste] = useState({
    id: 'entrada',
    nombre: 'Entrada',
  })

  useEffect(() => {
    setCantidad(Math.abs(producto.Cantidad))
    if (producto.Cantidad < 0) {
      setTipoAjuste({
        id: 'salida',
        nombre: 'Salida',
      })
    } else {
      setTipoAjuste({
        id: 'entrada',
        nombre: 'Entrada',
      })
    }

    if (tipoPedido !== 'control') {
      setDeposito(listaDepositos.find(deposito => deposito.Id === producto.IdDeposito))
    }
  }, [listaDepositos, producto, tipoPedido])


  const handleEditarProducto = () => {
    if (cantidad && cantidad >= 0) {
      producto.Cantidad = Math.abs(parseFloat(cantidad)) * (tipoAjuste.id === 'entrada' ? 1 : -1)
      if (tipoPedido !== 'control') {
        producto.IdDeposito = parseInt(deposito.id)
      }
      addStock(producto).then(() => {
        toast.success('Producto editado correctamente')
        history.goBack()
      }).catch(() => {
        toast.error('Error al editar el producto')
      })
    } else {
      toast.error('La cantidad debe ser mayor o igual a 0')
    }
  }

  return (
    <div>
      <div className={`flex flex-col align-top font-display font-bold mt-1 w-full  ${tipoPedido === 'control' ? 'hidden' : ''}`}>
        <ComboBox
          name="listaDepositos"
          text="Deposito"
          placeholder='Ver listado'
          textFieldName="Nombre"
          value={deposito}
          items={listaDepositos}
          onChange={(val) => setDeposito(val)}
        />
      </div>
      <div className={`flex flex-col align-top font-display font-bold mt-2 w-full  ${tipoPedido === 'ajuste' ? '' : 'hidden'}`}>
        <ComboBox
          name="tipoAjuste"
          text="Tipo de ajuste"
          placeholder='Ver listado'
          textFieldName="nombre"
          value={tipoAjuste}
          onChange={(val) => setTipoAjuste(val)}
          items={[{ id: "entrada", nombre: "Entrada" }, { id: "salida", nombre: "Salida" }]}
        />
      </div>
      <div className='flex flex-row align-top font-display font-bold mt-3'>
        <div className='flex-auto text-left align-top text-xs w-full'>
          <TextBox
            name="productos"
            label="Cantidad"
            type="number"
            enterhint="next"
            placeholder="Cantidad"
            autocomplete="off"
            value={cantidad}
            onChange={(e) => setCantidad(e)}
          />
        </div>
      </div>


      <div className='mt-4 text-sm flex flex-row align-top font-display'>
        <div className='flex-auto text-center px-2'>
          <button className='bg-secondaryColor w-full text-bgColor inline-block rounded shadow-md p-2 px-4 font-bold'
            onClick={() => { history.goBack() }}>
            Cancelar
          </button>
        </div>
        <div className='flex-auto text-center'>
          <button className='bg-primaryColor w-full text-bgColor inline-block rounded shadow-md p-2 px-4 font-bold'
            onClick={() => { handleEditarProducto() }}
            Guardar
          >
            Actualizar
          </button>
        </div>
      </div>
    </div>
  )
}

export default EdicionProductoForm
