import { useState, useEffect, useMemo } from 'react'
import { toMoneyFormat } from '../../Utils/Format'
import { addProducto, getProducto } from '../../Utils/db'
import TextBox from '../../Components/TextBox/TextBox'
import ComboBox from '../../Components/ComboBox'
import { useLocation, useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import Spinner from '../../Components/Spinner/Spinner'

function useQuery() {
  const { search } = useLocation()
  return useMemo(() => new URLSearchParams(search), [search])
}

const ProductoOrdenDetalle = ({ data, pedidos, precio, isLoading, tipoPedido, listadoPrecios }) => {
  const [cantidad, setCantidad] = useState(pedidos)
  const [precioVenta, setPrecioVenta] = useState(precio)
  const [listaPrecios, setListaPrecios] = useState([])
  const history = useHistory();
  const [canEdit, setCanEdit] = useState(false)
  let query = useQuery()
  const [editProducto, setEditProducto] = useState({})
  const [agregarButtonEnabled, setAgregarButtonEnabled] = useState(true)


  useEffect(() => {
    setCantidad(pedidos)
    setPrecioVenta(precio)
    getProducto(data.IdProducto, tipoPedido).then(res => {
      setListaPrecios(res ? res.listasDePrecios : listadoPrecios[0])
      setCanEdit(res ? res.canEdit : true)
      setEditProducto(res)
    })
  }, [pedidos, precio, data, tipoPedido, query, listadoPrecios])

  const handleQuitar = (producto) => {
    addProducto(producto, 0, null, null, tipoPedido).then(() => {
      setCantidad(0)
      history.goBack()
    })
  }

  const handleAgregar = (producto) => {
    let listaEfectiva = tipoPedido === 'venta' ? listadoPrecios.find(precio => precio.Id === parseInt(listaPrecios.id)) : {}

    if (!listaEfectiva) {
      listaEfectiva = editProducto ? editProducto.listasDePrecios : listadoPrecios[0]
    }

    if (cantidad !== 0) {
      addProducto(producto, cantidad, precioVenta, listaEfectiva, tipoPedido).then(res => {
        setCantidad(0)
        setPrecioVenta(0)
        toast.success(`${producto.Descripcion} agregado`)
        history.goBack()
      })
    }
  }

  const handleListaPrecios = (lista) => {
    setCanEdit(listadoPrecios.find(precio => precio.Id === parseInt(lista.id))?.PermitirModificarPrecio)
    setPrecioVenta(precio)
    setListaPrecios(lista)
  }

  const handleCantidadChange = (val) => {
    if (val <= 0) {
      setAgregarButtonEnabled(false)
    } else {
      setAgregarButtonEnabled(true)
    }
    setCantidad(val)
  }


  return (
    <div className='pb-40'>
      <div className='flex flex-row align-top font-display font-bold mt-2'>
        <div className='flex-auto text-left align-top text-sm'>
          <p className='text-secondaryColor font-bold text-sm'>Cod. {data.Codigo}</p>
          <p className='text-base font-bold uppercase'>{data.Descripcion}</p>
          <p className='font-semibold'>Precio: <span className='uppercase text-primaryColor'>$ {toMoneyFormat(data.PrecioVenta)}</span></p>
        </div>
      </div>
      {isLoading && <Spinner />}

      <div className='fixed bottom-0 left-0 w-full p-2 pb-3 px-4 bg-bgColor shadow-2xl overflow-auto' style={{ overscrollBehavior: 'contain' }}>
        <div className='flex flex-row align-top font-display font-bold mt-1'>
          <div className='flex-auto text-left align-top text-xs w-full'>
            <TextBox
              name="productos"
              label="Cantidad pedido"
              type="number"
              enterhint="next"
              placeholder="Cantidad"
              autocomplete="off"
              value={cantidad}
              onChange={(val) => handleCantidadChange(val)}
            />
          </div>
          <div className={`flex-auto text-left align-top text-xs w-full ml-2 ${tipoPedido == 'venta' ? '' : 'hidden'}`}>
            <TextBox
              name="precio"
              label="Precio"
              type="number"
              enterhint="next"
              placeholder="Precio"
              autocomplete="off"
              value={precioVenta}
              onChange={(val) => setPrecioVenta(val)}
              disabled={!canEdit}
            />
          </div>
        </div>
        <div className={`flex flex-col align-top font-display font-bold mt-1 w-full ${tipoPedido === 'venta' ? (listaPrecios ? '' : 'hidden') : 'hidden'}`}>
          <ComboBox
            name="listaPrecios"
            text="Lista de precios"
            placeholder='Ver listado'
            textFieldName="Nombre"
            value={listaPrecios}
            items={listadoPrecios}
            onChange={(val) => handleListaPrecios(val)}
          />
        </div>

        <div className='mt-4 text-sm flex flex-row align-top font-display'>
          <div className='flex-auto text-center px-2'>
            <button className='bg-secondaryColor w-full text-bgColor inline-block rounded shadow-md p-2 px-4 font-bold'
              onClick={() => { handleQuitar(data) }}
            >
              {pedidos ? 'Quitar' : 'Cancelar'}
            </button>
          </div>
          <div className='flex-auto text-center'>
            <button className='bg-primaryColor w-full text-bgColor inline-block rounded shadow-md p-2 px-4 font-bold disabled:cursor-not-allowed'
              disabled={!agregarButtonEnabled}
              onClick={() => { handleAgregar(data) }}
            >
              {pedidos ? 'Actualizar' : 'Agregar'}
            </button>
          </div>
        </div>
      </div>
    </div >
  )
}

export default ProductoOrdenDetalle
