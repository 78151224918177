import React from "react";
import PropTypes from "prop-types";

/**
 * Combobox con listado de items
 *
 * @version 1.0.0
 *
 */
function ComboBox(props) {
  let comboClass = `text-sm border border-secondaryColor focus:outline-none focus:ring-2 focus:ring-primaryColor focus:border-bgColor w-full rounded bg-primary overflow-hidden bg-none p-2 bg-bgColor ${props.icon ? 'pl-8' : 'pl-1'} ${props.className}`

  const handleChange = (e) => {
    props.onChange &&
      props.onChange({
        id: e.target.value,
        [props.textFieldName]: e.target.options[e.target.selectedIndex].text,
      });
  };


  return (
    <div>
      <label htmlFor={props.name}
        className='text-xs font-display font-bold'
        style={{ color: props.errorState ? "red" : null }}
      >
        {props.required ? "*" : null} {props.text}
      </label>
      <div className='control'>
        <select name={props?.name} onChange={handleChange}
          className={comboClass}
          style={props.style}
        >
          {(props.items?.length > 0 && props.value ) &&
            props.items?.map((item, key) => (
              <option
                className='bg-none'
                value={item.id ? item.id : item.Id}
                selected={
                  item[props.textFieldName] === props.value[props.textFieldName] ? "true" : null
                }
              >
                {item[props.textFieldName]}
              </option>
            ))}
          {props.addNA && (
            <option value="" selected>
              N/A
            </option>
          )}
        </select>
        <div className={`loader ${props.isLoading ? '' : 'hidden'}`}>
          <div className="dot-pulse text-primaryColor"></div>
        </div>
        <small className="textbox--error-text">
          {props.errorState && props.errorState}
        </small>
      </div>
    </div>
  );
}

ComboBox.propTypes = {
  /** Texto del label */
  text: PropTypes.string.isRequired,
  /** Idicador si el primer elemento es N/A */
  addNA: PropTypes.bool,
  /** Funcion onChange */
  onChange: PropTypes.func,
  /** Listado de opciones */
  items: PropTypes.arrayOf(PropTypes.object),
  /** Nombre del campo de texto */
  textFieldName: PropTypes.string,
  /** Indicador de carga de datos */
  isLoading: PropTypes.bool,
  selected: PropTypes.object,
};

ComboBox.defaultProps = {
  text: "",
  addNA: false,
  onChange: null,
  items: [],
  textFieldName: "nombre",
  isLoading: false,
};

export default ComboBox;
