import { useState } from 'react'
import axios from 'axios'
import { getTokenHeader, setToken, setUserData } from '../../../Utils/LocalStorage'
import { moneyType, toMoneyFormat, formatFecha } from '../../../Utils/Format'


const CuentaDetalleLista = ({ tipo, data, moneda, onClick, special, isHeader }) => {
  // Saldo del primer movimiento
  if (!data) {
    return null
  }

  return (
    <tr className={`flex flex-row align-top font-display font-bold text-xs px-1 py-2 cursor-pointer ${special ? 'mb-3 border-b-2' : ''}`} onClick={() => !(special || data.ObjectKey === '') && onClick(data.ObjectKey)}>
      <td className='flex-auto text-left align-top uppercase font-normal'>

        <p className={`font-semibold uppercase`}>
          {special &&
            <div className="flex flex-row">
              <span className="w-full">
                Saldo a la fecha
              </span>
              <span className="text-right uppercase text-base font-bold">
                {moneyType(moneda)}{isHeader ? toMoneyFormat(data.Saldo) : 0}
              </span>
            </div>}
          {!special &&
            <span>
              {data.Descripcion ? data.Descripcion : (data.Remito ? data.Remito : (data.NotaDePedido ? data.NotaDePedido : data.Factura))}
            </span>}
        </p>

        <p className={`uppercase text-primaryColor ${special ? 'hidden' : 'block'}`}>{formatFecha(data?.Fecha)}</p>
      </td>
      <td className={`flex-auto text-right align-top ${!special ? '' : 'hidden'}`}>
        <h1 className={`uppercase text-base -mt-1`}>{moneyType(moneda)} {toMoneyFormat(special ? data?.Saldo : data?.Importe)}</h1>
      </td>
    </tr>
  )
}

export default CuentaDetalleLista

