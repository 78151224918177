import { PropTypes } from 'react'
import { formatNumber } from '../../../../Utils/Format'

const StockItem = ({ data, onClick }) => {
  return (
    <tr className='flex flex-row align-top font-display font-bold text-xs px-1 py-2'
      onClick={() => onClick(data)}
    >
      <td className='flex-auto text-left align-top uppercase font-normal pr-3'>
        <p className='text-secondaryColor font-bold lowercase break-all'>{data.Categoria}</p>
        <p className='uppercase'>{data.Descripcion}</p>
      </td>
      <td className='flex-auto text-right align-top'>
        <h1 className='uppercase text-base -mt-1 ml-6' style={{ whiteSpace: 'nowrap' }}>{formatNumber(data.Existencia)}</h1>
      </td>
    </tr>
  )
}

export default StockItem
