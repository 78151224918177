import { useState, useEffect } from 'react'
import { formatFecha } from '../../Utils/Format'

const ExistenciasItem = ({ item, setDetalle, setShowDetalle, special }) => {
  return (
    <tr className={`flex flex-row align-top font-display font-bold text-xs px-1 py-2 cursor-pointer ${special ? 'mb-3 border-b-2' : ''}`}
      onClick={() => console.log('test')}>
      <td className='flex-auto text-left align-top uppercase font-normal'>

        <p className={`font-semibold uppercase`}>
          {special &&
            <div className="flex flex-row">
              <span className="w-full">
                Existencia anterior
              </span>
              <span className="text-right uppercase text-base font-bold">
                {item.Existencia}
              </span>
            </div>}
          {!special &&
            <span>
              {item.Descripcion}
            </span>}
        </p>

        <p className={`uppercase text-primaryColor ${special ? 'hidden' : 'block'}`}>{formatFecha(item?.Fecha)}</p>
      </td>
      <td className={`flex-auto text-right align-top ${!special ? '' : 'hidden'}`}>
        <h1 className={`uppercase text-base -mt-1`}>{item.Cantidad}</h1>
      </td>
    </tr>
  )
}

export default ExistenciasItem
