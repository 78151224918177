import { useMemo, PropTypes, useState, useEffect } from 'react'
import Layout from '../../Components/Layout.js'
import Header from '../../Components/Header/Header'
import TextBox from '../../Components/TextBox/TextBox'
import Placeholder from '../../Components/Placeholder/Placeholder'
import ComboBox from '../../Components/ComboBox'
import axios from 'axios'
import { getTokenHeader, setToken, setUserData } from '../../Utils/LocalStorage'
import { formatFechaISO } from '../../Utils/Format'
import { addDays } from '../../Utils/Utils'
import { useLocation, useHistory } from 'react-router-dom'
import Lista from './CuentasDetalleLista/CuentasDetalleLista'
import ListaComprobantes from './CuentasDetalleListaComprobantes/CuentasDetalleListaComprobantes'
import DetalleOverlay from '../../Components/DetalleOverlay/DetalleOverlay'
import CuentaDetalle from './CuentasDetalle/CuentasDetalle'
import { toast } from 'react-toastify'


// FACTURAS GARBUGLIA
// REMITOS CAMILETTI
// N PEDIDOS JABALI

function useQuery() {
  const { search } = useLocation()
  return useMemo(() => new URLSearchParams(search), [search])
}

const CuentaDetallePage = ({ titulo, subtitulo }) => {
  const [cuenta, setCuenta] = useState({})
  const [cuentas, setCuentas] = useState([])

  const [showDetalle, setShowDetalle] = useState(false)
  const [detalle, setDetalle] = useState({})

  const [queryData, setQueryData] = useState([])
  const [modoAgrupacion, setModoAgrupacion] = useState({ id: 0, nombre: 'NINGUNO' })
  const [tipo, setTipo] = useState({ id: 'MOVIMIENTO', nombre: 'MOVIMIENTOS POR FECHA' })


  const [desde, setDesde] = useState(formatFechaISO(addDays(Date.now(), -30)))
  const [hasta, setHasta] = useState(formatFechaISO(addDays(Date.now(), 0)))
  const [isLoading, setIsLoading] = useState(false)

  let query = useQuery()
  const history = useHistory();


  // Inizializacion de datos
  useEffect(() => {
    setIsLoading(true)
    axios.get(`${process.env.REACT_APP_ENDPOINT}/ventas/GetCuentasPorIdCliente?idCliente=${atob(query.get('c'))}`, getTokenHeader()).then(res => {
      setCuentas(res.data.Result)
      setCuenta(res.data.Result.find(c => c.Id == query.get('ci')))
      setToken(res.data.RenewedAccessToken, res.data.RenewedAccessTokenExpireTime)

      axios.get(`${process.env.REACT_APP_ENDPOINT}/ventas/GetCtaCte?idCuentaCliente=${query.get('ci')}&fechaDesde=${desde}&fechaHasta=${hasta}&modoIncluirAgrupacion=${modoAgrupacion.id}`, getTokenHeader()).then(res => {
        setQueryData(res.data.Result)
        setToken(res.data.RenewedAccessToken, res.data.RenewedAccessTokenExpireTime)
        setIsLoading(false)
      })
    }).catch(err => {
      setIsLoading(false)
      if (err.response.status === 401) {
        setToken('')
        window.location.href = '/login'
      } else {
        toast.error(err.response.data.Message)
      }
    })
  }, [])

  const handleChange = (field, value) => {
    let qDesde, qHasta, qModoAgrupacion, qTipo, qCuenta
    switch (field) {
      case 'desde':
        qDesde = value
        qHasta = hasta
        qModoAgrupacion = modoAgrupacion
        qTipo = tipo
        qCuenta = cuenta
        setDesde(value)
        break
      case 'hasta':
        qHasta = value
        qDesde = desde
        qModoAgrupacion = modoAgrupacion
        qTipo = tipo
        qCuenta = cuenta
        setHasta(value)
        break
      case 'modoAgrupacion':
        qDesde = desde
        qHasta = hasta
        qModoAgrupacion = value
        qTipo = tipo
        qCuenta = cuenta
        setModoAgrupacion(value)
        break
      case 'tipo':
        qDesde = desde
        qHasta = hasta
        qModoAgrupacion = modoAgrupacion
        qTipo = value
        setTipo(value)
        qCuenta = cuenta
        break
      default:
        qDesde = desde
        qHasta = hasta
        qModoAgrupacion = modoAgrupacion
        qTipo = tipo
        qCuenta = value
        setCuenta(value)
        break
    }

    let endpoint

    if (qTipo?.id) {
      switch (qTipo.id) {
        case 'MOVIMIENTO':
          endpoint = 'GetCtaCte'
          break
        case 'FACTURA':
          endpoint = 'GetFacturasPendientes'
          break
        case 'REMITO':
          endpoint = 'GetRemitosPendientes'
          break
        default:
          endpoint = 'GetNotasDePedidoPendientes'
          break
      }
    }

    axios.get(`${process.env.REACT_APP_ENDPOINT}/ventas/${endpoint}?idCuentaCliente=${qCuenta.id ? qCuenta.id : qCuenta.Id}&fechaDesde=${qDesde}&fechaHasta=${qHasta}&modoIncluirAgrupacion=${qModoAgrupacion?.id}`, getTokenHeader()).then(res => {
      setQueryData(res.data.Result)
      setToken(res.data.RenewedAccessToken, res.data.RenewedAccessTokenExpireTime)
    })
      .catch(err => {
        if (err?.response.status === 401) {
          setToken('')
        } else {
          toast.error(err.response.data.ErrorMessage)
        }
      })
  }


  // Handler para mostrar el detalle de un movimiento
  const handleShowDetalle = (objectKey) => {
    axios.get(`${process.env.REACT_APP_ENDPOINT}/comprobantes/GetDetalleComprobante?objectKey=${objectKey}`, getTokenHeader()).then(res => {
      let det = res.data
      det.objectKey = objectKey
      setDetalle(det)
      history.push(`/clientes/cuentas/detalle/${objectKey}?openDetail=o`)
    })
  }


  return (
    <Layout>
      <Header titulo={atob(query.get('u'))} subtitulo='Cuentas' toAddress='/clientes/cuentas?norefresh=true' />

      <div className='mt-14 flex flex-row'>
        <div className='flex-auto pr-1'>
          <ComboBox
            name="Ver"
            text="Cuenta"
            placeholder='Ver listado'
            items={cuentas}
            textFieldName="NombreCompleto"
            onChange={(value) => { handleChange('cuenta', value) }}
            value={cuenta}
          />
        </div>
      </div>
      <div className='mt-2 flex flex-row'>
        <div className='flex-auto w-4/12 pr-1'>
          <TextBox
            name="usuario"
            label="Desde"
            type="date"
            enterhint="next"
            placeholder="Buscar cliente"
            value={desde}
            onChange={(val) => { handleChange('desde', val) }}
          />
        </div>
        <div className='flex-auto w-4/12 pr-1'>
          <TextBox
            name="usuario"
            label="Hasta"
            type="date"
            enterhint="next"
            placeholder="Buscar cliente"
            value={hasta}
            onChange={(val) => { handleChange('hasta', val) }}
          />
        </div>
        <div className='flex-auto w-4/12 pr-1'>
          <ComboBox
            name="Agrupación"
            text="Agrupación"
            placeholder='Tipo de agrupación'
            items={[
              { id: "0", nombre: "NINGUNO" },
              { id: "1", nombre: "POR CLIENTE" },
              { id: "2", nombre: "POR GRUPO" },
            ]}
            textFieldName="nombre"
            value={modoAgrupacion}
            onChange={(val) => { handleChange('modoAgrupacion', val) }}
          />
        </div>
      </div>
      <div className='mt-2 flex flex-row'>
        <div className='flex-auto pr-1'>
          <ComboBox
            name="Ver"
            text="Ver"
            placeholder='Ver listado'
            items={[
              { id: "MOVIMIENTO", nombre: "MOVIMIENTOS POR FECHA" },
              { id: "REMITO", nombre: "REMITOS PENDIENTES" },
              { id: "FACTURA", nombre: "FACTURAS PENDIENTES" },
              { id: "NOTA DE PEDIDO", nombre: "N. PEDIDOS PENDIENTES" },
            ]}
            textFieldName="nombre"
            value={tipo}
            onChange={(val) => { handleChange('tipo', val) }}
          />
        </div>
      </div>

      <table className='mt-4 table'>
        {tipo.id === 'MOVIMIENTO' &&
          <Lista
            tipo={tipo}
            data={queryData[0]}
            moneda={cuenta.Moneda}
            special={true}
            isHeader={true}
          />
        }
        {tipo.id === 'MOVIMIENTO' && queryData.map((mov, key) => (
          <Lista
            tipo={tipo}
            data={mov}
            moneda={cuenta.Moneda}
            onClick={(val) => { handleShowDetalle(val) }}
          />
        ))}
        {tipo.id !== 'MOVIMIENTO' && queryData.map((mov, key) => (
          <ListaComprobantes
            tipo={tipo}
            data={mov}
            moneda={cuenta.Moneda}
            onClick={(val) => { handleShowDetalle(val) }}
          />
        ))}
      </table>
      {queryData.length === 0 && !isLoading &&
        < div >
          <Placeholder titulo='Sin resultado' texto='No se encontraron movimientos en ese período de tiempo. Realice una nueva búqueda.' />
        </div>
      }


      <DetalleOverlay
        show={showDetalle}
        titulo={`Detalle de ${tipo.id.toLowerCase()}`}
        onClose={() => { history.goBack() }}>
        <CuentaDetalle data={detalle} />

      </DetalleOverlay>
    </Layout >
  )
}

export default CuentaDetallePage
