import { useState, useEffect, useMemo } from 'react'
import { addStock, deleteStockItem } from '../../../../Utils/db'
import TextBox from '../../../../Components/TextBox/TextBox'
import ComboBox from '../../../../Components/ComboBox'
import { useLocation, useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

function useQuery() {
  const { search } = useLocation()
  return useMemo(() => new URLSearchParams(search), [search])
}

const StockDetalle = ({ data, dbItem, tipoPedido, depositoId }) => {
  const [cantidad, setCantidad] = useState(dbItem?.Cantidad || 0)
  const [tipoAjuste, setTipoAjuste] = useState({
    id: 'entrada',
    nombre: 'Entrada',
  })
  const history = useHistory();
  let query = useQuery()


  useEffect(() => {
    setCantidad(Math.abs(dbItem?.Cantidad || 0))
    if (dbItem?.Cantidad < 0) {
      setTipoAjuste({
        id: 'salida',
        nombre: 'Salida',
      })
    } else {
      setTipoAjuste({
        id: 'entrada',
        nombre: 'Entrada',
      })
    }
  }, [dbItem, data, tipoPedido, query])

  const handleQuitar = () => {
    setCantidad(0)
    if (dbItem) {
      deleteStockItem(dbItem._id).then(res => {
        toast.success('Se ha eliminado el producto del stock')
        history.push(`/stock/${tipoPedido}?norefresh=true&depositoId=${depositoId}`)
      }).catch(err => {
        toast.error('No se pudo eliminar el producto del stock')
      })
    } else {
      history.push(`/stock/${tipoPedido}?norefresh=true&depositoId=${depositoId}`)
    }
  }

  const handleAgregar = (productoNuevo) => {
    let stockItem = {
      _id: dbItem?._id,
      Id: productoNuevo.IdProducto,
      IdSubproducto: productoNuevo.IdSubProducto,
      IdDeposito: depositoId,
      Cantidad: parseFloat(cantidad),
      nombre: data.Descripcion,
      tipo: tipoPedido,
    }

    if (cantidad && tipoPedido !== 'control' && cantidad <= 0) {
      toast.error('La cantidad debe ser mayor a 0')
      return
    }

    if (cantidad && cantidad >= 0) {
      if (tipoPedido === 'ajuste') { stockItem.Cantidad = parseFloat(Math.abs(stockItem.Cantidad)) * (tipoAjuste.id === 'salida' ? -1 : 1) }
      addStock(stockItem)
      toast.success('Se agrego el producto al stock')
      setCantidad(0)
      history.push(`/stock/${tipoPedido}?norefresh=true&depositoId=${depositoId}`)
    } else {
      setCantidad(0)
      toast.error('La cantidad debe ser mayor a 0')
    }
  }

  return (
    <div className='pb-40'>
      <div className='flex flex-row align-top font-display font-bold mt-2'>
        <div className='flex-auto text-left align-top text-sm'>
          <p className='text-secondaryColor font-bold text-sm'>Cod. {data.Codigo}</p>
          <p className='text-base font-bold uppercase'>{data.Descripcion}</p>
          <p className='font-bold text-xs mt-2'>Existencias en el depósito: {data.Existencia}</p>
        </div>
      </div>

      <div className='fixed bottom-0 left-0 w-full p-2 pb-3 px-4 bg-bgColor shadow-2xl overflow-auto' style={{ overscrollBehavior: 'contain' }}>
        <div className={`flex flex-col align-top font-display font-bold mt-1 w-full ${tipoPedido === 'ajuste' ? '' : 'hidden'}`}>
          <ComboBox
            name="tipoAjuste"
            text="Tipo de ajuste"
            placeholder='Ver listado'
            textFieldName="nombre"
            value={tipoAjuste}
            onChange={(val) => setTipoAjuste(val)}
            items={[{ id: "entrada", nombre: "Entrada" }, { id: "salida", nombre: "Salida" }]}
          />
        </div>
        <div className='flex flex-row align-top font-display font-bold mt-2'>
          <div className='flex-auto text-left align-top text-xs w-full'>
            <TextBox
              name="productos"
              label={`${tipoPedido === 'control' ? 'Existencia real' : 'Cantidad'}`}
              type="number"
              enterhint="next"
              placeholder="Cantidad"
              autocomplete="off"
              value={cantidad}
              onChange={(val) => setCantidad(val)}
            />
          </div>
        </div>
        <div className={`flex flex-row align-top font-display font-bold mt-4 ${tipoPedido === 'control' ? '' : 'hidden'}`}>
          <div className='flex-auto text-left align-top text-sm w-full'>
            <p>Diferencia a ajustar: {cantidad - data.Existencia}</p>
          </div>
        </div>

        <div className='mt-4 text-sm flex flex-row align-top font-display'>
          <div className='flex-auto text-center px-2'>
            <button className='bg-secondaryColor w-full text-bgColor inline-block rounded shadow-md p-2 px-4 font-bold'
              onClick={() => { handleQuitar(data) }}
            >
              {dbItem ? 'Quitar' : 'Cancelar'}
            </button>
          </div>
          <div className='flex-auto text-center'>
            <button className='bg-primaryColor w-full text-bgColor inline-block rounded shadow-md p-2 px-4 font-bold'
              onClick={() => { handleAgregar(data) }}
            >
              Aceptar
            </button>
          </div>
        </div>
      </div>
    </div >
  )
}

export default StockDetalle



