import { PropTypes, useEffect } from 'react'

import { tokenExpired, logout } from '../Utils/LocalStorage'

const Layout = props => {
  useEffect(() => {
    if (tokenExpired()) {
      logout()
    }
  }, [])

  return (
    <div className="bg-bgColor flex-col md:container md:mx-auto flex px-4 pt-4 min-h-screen pb-16">
      {props.children}
    </div>
  )
}

Layout.propTypes = {

}

export default Layout
